import React, { Dispatch, FC, SetStateAction } from "react";
import { ShortCategoryType } from "modules/KnowledgeBase/types/types";
import FormControl from "@mui/material/FormControl";
import { Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

type SelectCategoryProps = {
  allCategories: ShortCategoryType[];
  setSelectedCategory: Dispatch<SetStateAction<string>>;
  selectedCategory: string;
};

const SelectCategory: FC<SelectCategoryProps> = ({ allCategories, selectedCategory, setSelectedCategory }) => {
  return (
    <>
      <FormControl fullWidth sx={{ paddingX: 1 }}>
        <Select value={selectedCategory} onChange={(event) => setSelectedCategory(event.target.value)}>
          {allCategories.map((item) => {
            return (
              <MenuItem key={item.id} value={item.modelName}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectCategory;
