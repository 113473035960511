import React, { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import * as Yup from "yup";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, CardMedia, Grid, Paper, Typography } from "@mui/material";

import backend from "api/backend";

import CustomizedDropZone from "components/Edit/CustomizedDropZone";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import CustomizedTextField from "components/Edit/CustomizedTextField";

import { isSuccess } from "utils/http";
import getImage from "utils/imagesUrl";

const endpoint = "/media/";
const title = "cms_media_edit_label";
const moduleUrl = "/cms/media/";

const MediaEdit: ForwardRefRenderFunction<HTMLTextAreaElement, any> = () => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    title: Yup.string().trim().required(t("form-validation-required")),
  });

  const refForm = useRef(null);

  const childRef = useRef<HTMLElement & { uploadFile: () => void }>(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        customSaveFunc={() => childRef?.current?.uploadFile()}
      >
        <FormDataCustom id={id} refForm={refForm} ref={childRef} />
      </EditViewNoLanguage>
    </>
  );
};

// eslint-disable-next-line react/display-name
const FormDataCustom = forwardRef<any, { id: string; refForm: any; ref: any }>((props, ref) => {
  const { id, refForm } = props;
  const idIsNumber = !isNaN(id as any);

  const { watch, setValue, getValues } = useFormContext();

  const watchUrl = watch("url", "");
  const watchAlt = watch("altText", "");
  const watchDimension = watch("dimensions", "");
  const watchfileExtension = watch("fileExtension", "");
  const watchFileSize = watch("fileSize", "");

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  useEffect(() => {
    if (selectedFile !== null) {
      setValue("name", selectedFile["name"]);
      setValue("fileExtension", selectedFile["type"]);
      setValue("fileSize", selectedFile["size"]);
      setValue("fileData", selectedFile);
    }
  }, [selectedFile]);

  useImperativeHandle(ref, () => ({
    uploadFile() {
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", getValues("fileData"));
        formData.append("name", getValues("name"));
        const headers = {
          "Content-Type": `multipart/form-data;`,
        };
        backend.post("/media/upload", formData, { headers: headers }).then((response) => {
          if (isSuccess(response)) {
            const { data } = response;
            if (data.ok) {
              setValue("name", data.name);
              setValue("dimensions", data.width + "px x " + data.height + "px");
              refForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
            }
          } else {
            console.error("can't save image");
          }
        });
      } else {
        refForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
      }
    },
    testFunction() {
      console.info("child function 2 called");
    },
  }));

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          {watchUrl?.length > 0 ? (
            <>
              <Grid item xs={12} sm={4}>
                <CardMedia component="img" image={getImage(watchUrl)} alt={watchAlt} sx={{ maxWidth: "250px" }} />
              </Grid>
              <Grid item xs={12} sm={8}>
                {/*<CustomizedDropZone label={"Podmień plik"} selectedFile={(e) => setSelectedFile(e)}/>*/}
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12}>
              <CustomizedDropZone
                label={"Wybierz plik"}
                selectedFile={(e) => setSelectedFile(e)}
                insideLabel="Przeciągnij lub wybierz plik graficzny"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <CustomizedTextField label={"Nazwa pliku"} name={"name"} disabled={idIsNumber} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomizedTextField label={"Tytuł"} name={"title"} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomizedTextField label={"Tekst alternatywny"} name={"altText"} />
          </Grid>
        </Grid>
      </Paper>
      {idIsNumber && (
        <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
          <Accordion square defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ p: 0 }}
            >
              <Typography variant="h4" component={"h4"}>
                Szczegóły
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, mb: 5 }}>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell>URL</TableCell>
                      <TableCell>{watchUrl}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Rozszerzenie</TableCell>
                      <TableCell>{watchfileExtension}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Rozmiar</TableCell>
                      <TableCell>{watchFileSize ? Math.round(watchFileSize / 1024) + " kb" : ""} </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Wymiary</TableCell>
                      <TableCell>{watchDimension}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
    </>
  );
});

export default forwardRef(MediaEdit);
