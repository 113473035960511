import React, { ForwardRefRenderFunction, forwardRef, useRef, useState, useEffect, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import * as Yup from "yup";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from "@mui/material";

import EditViewNoLanguage from "components/EditViewNoLanguage";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import KnowledgeDocuments from "modules/settings/Documents/KnowledgeDocuments";
import { useFormContext } from "react-hook-form";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import KnowledgeCategoryDetailsTable from "modules/settings/Documents/KnowledgeCategoryDetailsTable";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { selectEditItem, fetchCrudItem } from "redux/slices/crud";

const endpoint = "/knowledge-category/";
const title = "document_edit_knowledge_category_label";
const moduleUrl = "/documents/categories/";
const endpointGetAllDocuments = endpoint + "get-documents";
const endpointStartIndex = endpoint + "start-index";

const KnowledgeCategoryEdit: ForwardRefRenderFunction<HTMLTextAreaElement, any> = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    id: Yup.string().nullable(),
    name: Yup.string().trim().required(t("form-validation-required")),
    description: Yup.string(),
    documents: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required(),
        }),
      )
      .nullable(),
  });

  const refForm = useRef(null);
  const childRef = useRef<HTMLElement & { imperativeHandleIndex: () => void }>(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        actionIndexKnowledge={() => childRef?.current?.imperativeHandleIndex()}
        headerData={[
          {
            name: "document_edit_knowledge_index_button",
            tooltip: "document_edit_knowledge_index_button",
            icon: "",
            action: "index_knowledge",
            disabled: isNaN(id as any),
            sortOrder: 0,
            type: "BUTTON",
          },
        ]}
      >
        <FormDataCustom id={id} refForm={refForm} ref={childRef} />
      </EditViewNoLanguage>
    </>
  );
};

// eslint-disable-next-line react/display-name
const FormDataCustom = forwardRef<any, { id: string; refForm: any; ref: any }>((props, ref) => {
  const { id } = props;
  const idIsNumber = !isNaN(id as any);
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const current = useSelector(selectEditItem);
  const dispatch = useDispatch();

  const [allDocuments, setAllDocuments] = useState<null | { id: number; name: string }[]>(null);

  const { watch, getValues } = useFormContext();
  const documents = watch("documents");
  const details = watch("details");

  useEffect(() => {
    const getUsers = () => {
      backend
        .get(endpointGetAllDocuments)
        .then((res) => {
          if (isSuccess(res) && res.data) {
            setAllDocuments(res.data);
          }
        })
        .catch((reason) => {
          setAllDocuments([]);
          console.error(reason);
        });
    };

    getUsers();
  }, []);

  useImperativeHandle(ref, () => ({
    async imperativeHandleIndex() {
      if (isDirty()) {
        enqueueSnackbar(t("document_edit_knowledge_save_changes"), {
          variant: "warning",
        });
        return;
      }

      const startIndex = () => {
        backend
          .get(endpointStartIndex + "/" + id)
          .then((res) => {
            if (isSuccess(res) && res.data === "OK") {
              enqueueSnackbar(t("document_edit_knowledge_task_initiated"), {
                variant: "success",
              });
              dispatch(fetchCrudItem({ endpoint, id }));
            } else {
              enqueueSnackbar(t("document_edit_knowledge_task_initiated_error"), {
                variant: "error",
              });
            }
          })
          .catch((reason) => {
            enqueueSnackbar(t("document_edit_knowledge_task_initiated_error"), {
              variant: "error",
            });
            console.error(reason);
          });
      };

      startIndex();
    },
  }));

  const isDirty = () => {
    const orgObj = {
      name: current["name"],
      description: current["description"],
      documents: current["documents"],
    };
    const currentObj = {
      name: getValues("name"),
      description: getValues("description"),
      documents: getValues("documents"),
    };

    return JSON.stringify(orgObj) !== JSON.stringify(currentObj);
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <CustomizedTextField label={t("document_own_edit_file_name")} name="name" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomizedTextField label={t("document_own_edit_description")} name="description" rows={4} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <KnowledgeDocuments allDocuments={allDocuments} selectedDocuments={documents} />
          </Grid>
        </Grid>
      </Paper>
      {idIsNumber && (
        <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
          <Accordion square defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ p: 0 }}
            >
              <Typography variant="h4" component={"h4"}>
                {t("document_own_edit_details")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, mb: 5 }}>
              <KnowledgeCategoryDetailsTable details={details} />
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
    </>
  );
});

export default forwardRef(KnowledgeCategoryEdit);
