import React, { FC, memo } from "react";

import Dialog from "@mui/material/Dialog";
import { Card, CardActionArea, CardMedia } from "@mui/material";
type ImagePreviewDialogProps = {
  openImage: boolean;
  closeImage: () => void;
  imageUrl: string;
};
const ImagePreviewDialog: FC<ImagePreviewDialogProps> = ({ openImage, closeImage, imageUrl }) => {
  return (
    <Dialog open={openImage} onClose={closeImage}>
      <Card sx={{ borderRadius: 0 }}>
        <CardActionArea onClick={closeImage}>
          <CardMedia component="img" src={imageUrl} />
        </CardActionArea>
      </Card>
    </Dialog>
  );
};

export default memo(ImagePreviewDialog);
