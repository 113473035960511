import { Grid, Paper, Typography } from "@mui/material";
import Layout from "layout/Layout";

import React from "react";
import News from "./components/News";
import MainNews from "./components/MainNews";

const NewsPage = () => {
  return (
    <Layout hiddenMenu>
      <Typography variant="h1">News</Typography>

      <Paper sx={{ padding: 4 }}>
        <MainNews />
      </Paper>

      <Grid container>
        <Grid item xs={6} p={2}>
          <News />
        </Grid>
        <Grid item xs={6} p={2}>
          <News />
        </Grid>
        <Grid item xs={12}>
          <h2>Technology</h2>
        </Grid>
        <Grid item xs={4} flexDirection="column" p={2}>
          <News />
        </Grid>
        <Grid item xs={4} flexDirection="column" p={2}>
          <News />
        </Grid>
        <Grid item xs={4} flexDirection="column" p={2}>
          <News />
        </Grid>
        <Grid item xs={12}>
          <h2>Economy</h2>
        </Grid>
        <Grid item xs={4} flexDirection="column" p={2}>
          <News />
        </Grid>
        <Grid item xs={4} flexDirection="column" p={2}>
          <News />
        </Grid>
        <Grid item xs={4} flexDirection="column" p={2}>
          <News />
        </Grid>
      </Grid>
    </Layout>
  );
};
export default NewsPage;
