import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

import { Grid, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";

import backend from "api/backend";

import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import CustomizedSelect from "components/Edit/CustomizedSelect";

import { isSuccess } from "utils/http";

const endpoint = "/users/";
const title = "settings_user_edit_label";
const moduleUrl = "/settings/users/";

const UserEdit: FC = () => {
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    roleId: Yup.string().trim().required(t("form-validation-required")),
    active: Yup.bool().required(t("form-validation-required")),
    name: Yup.string().trim().required(t("form-validation-required")),
    login: Yup.string().trim().required(t("form-validation-required")),
    email: Yup.string().email(t("form-validation-mail")).max(255).required(t("form-validation-required")),
    repeatedPassword: Yup.string().test("passwords-match", t("form-validation-match-password"), function (value) {
      return this.parent.password === value;
    }),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
      >
        <FormData />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = () => {
  const [roleTypes, setRoleTypes] = useState([]);

  useEffect(() => {
    backend.get("/roles/all").then((res) => {
      if (isSuccess(res)) {
        setRoleTypes(res.data);
      }
    });
  }, []);

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect label={"Rola"} name={"roleId"} items={roleTypes} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitch topLabel={"&nbsp;"} rightLabel={"Aktywny"} name={"active"} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Nazwa"} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Login"} name={"login"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Email"} name={"email"} />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Hasło"} name={"password"} type={"password"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Powtórz hasło"} name={"repeatedPassword"} type={"password"} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default UserEdit;
