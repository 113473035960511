import TableContainer from "@mui/material/TableContainer";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { FC } from "react";

type DetailsTablePropsType = {
  details: any[];
};

const KnowledgeCategoryDetailsTable: FC<DetailsTablePropsType> = ({ details }) => {
  const { t } = useTranslation("common");

  //align="right" sx={{ width: "5%" }}

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("document_edit_detail_name")}</TableCell>
              <TableCell sx={{ width: "5%" }}>{t("document_edit_detail_status")}</TableCell>
              <TableCell align="right" sx={{ width: "15%" }}>
                {t("document_edit_detail_part_size")}
              </TableCell>
              <TableCell align="right" sx={{ width: "15%" }}>
                {t("document_edit_detail_common_size")}
              </TableCell>
              <TableCell sx={{ width: "15%" }}>{t("document_edit_detail_common_created")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {details?.map((doc) => {
              return (
                <TableRow key={doc.id}>
                  <TableCell>{doc.name}</TableCell>
                  <TableCell>{doc.status}</TableCell>
                  <TableCell align="right">{doc.partSize}</TableCell>
                  <TableCell align="right">{doc.commonSize}</TableCell>
                  <TableCell>
                    <Box component="span" sx={{ whiteSpace: "nowrap" }}>
                      {doc.created}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default KnowledgeCategoryDetailsTable;
