import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";

import "./assets/styles/scss/global.scss";
import Page404 from "modules/Page404";
import InboxList from "modules/settings/Home/InboxList";
import EntriesList from "modules/settings/CMSDocuments/EntriesList";
import CmsCategoriesList from "modules/settings/CMSDocuments/CategoriesList";
import CommentsList from "modules/settings/CMSDocuments/CommentsList";
import CmsMediaList from "modules/settings/CMSResources/MediaList";
import FaqList from "modules/settings/CmsComponents/FaqList";
import UsersList from "modules/settings/SettingsAccess/UsersList";
import RolesList from "modules/settings/SettingsAccess/RolesList";
import LanguagesList from "modules/settings/SettingsConfiguration/LanguagesList";
import LogList from "modules/settings/SettingsConfiguration/LogList";
import CommentEdit from "modules/settings/CMSDocuments/CommentEdit";
import EntryEdit from "modules/settings/CMSDocuments/EntryEdit";
import CategoryEdit from "modules/settings/CMSDocuments/CategoryEdit";
import MediaEdit from "modules/settings/CMSResources/MediaEdit";
import FaqEdit from "modules/settings/CmsComponents/FaqEdit";
import LanguageEdit from "modules/settings/SettingsConfiguration/LanguageEdit";
import SmsSettings from "modules/settings/SettingsIntegrations/SmsEdit";
import MailSettings from "modules/settings/SettingsIntegrations/MailEdit";
import UserEdit from "modules/settings/SettingsAccess/UserEdit";
import RoleEdit from "modules/settings/SettingsAccess/RoleEdit";
import Shortcuts from "modules/settings/Home/Shortcuts";
import StartPage from "modules/settings/Home/StartPage";

import OrganizationsList from "modules/settings/SettingsAccess/OrganizationsList";
import OrganizationEdit from "modules/settings/SettingsAccess/OrganizationEdit";

import Login from "modules/Login";

import { fetchCurrentUser, fetchIsLogged, selectIsLogged } from "redux/slices/user";
import { fetchMenu } from "redux/slices/menu";

import { PublicRoute } from "components/PublicRoute";
import { PrivateRoute } from "components/PrivateRoute";
import { fetchOrganiaztionList } from "redux/slices/organizations";
import DashboardPage from "modules/Dashboard/DashboardPage";
import NewsPage from "modules/News/NewsPage";
import CoursesPage from "modules/Courses/CoursesPage";
import FaqPage from "modules/Faq/FaqPage";
import KnowledgeBasePage from "modules/KnowledgeBase/KnowledgeBasePage";
import GroupView from "modules/settings/SettingsAccess/GroupView";
import OwnDocumentList from "modules/settings/Documents/OwnDocumentList";
import OwnDocumentEdit from "modules/settings/Documents/OwnDocumentEdit";
import SharedForMeDocumentList from "modules/settings/Documents/SharedForMeDocumentList";
import SharedDocument from "modules/settings/Documents/SharedDocument";
import KnowledgeCategoryList from "modules/settings/Documents/KnowledgeCategoryList";
import KnowledgeCategoryEdit from "modules/settings/Documents/KnowledgeCategoryEdit";

function App() {
  const isLogged = useSelector(selectIsLogged);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIsLogged());
  }, []);

  useEffect(() => {
    if (isLogged) {
      dispatch(fetchCurrentUser());
      dispatch(fetchMenu());
      dispatch(fetchOrganiaztionList());
    }
  }, [isLogged]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <PrivateRoute isLogged={isLogged} exact path="/" component={StartPage} />

          <PrivateRoute isLogged={isLogged} exact path="/dashboard" component={DashboardPage} />
          <PrivateRoute isLogged={isLogged} exact path="/news" component={NewsPage} />
          <PrivateRoute isLogged={isLogged} exact path="/courses" component={CoursesPage} />
          <PrivateRoute isLogged={isLogged} exact path="/faq" component={FaqPage} />
          <PrivateRoute isLogged={isLogged} exact path="/knowledge-base" component={KnowledgeBasePage} />
          <PrivateRoute isLogged={isLogged} exact path="/knowledge-base/:uuid" component={KnowledgeBasePage} />

          <PrivateRoute isLogged={isLogged} exact path="/home/inbox" component={InboxList} />
          <PrivateRoute isLogged={isLogged} exact path="/home/shortcuts" component={Shortcuts} />

          <PrivateRoute isLogged={isLogged} exact path="/cms/posts" component={EntriesList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/posts/:id" component={EntryEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/posts/:id/:langCode" component={EntryEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/categories" component={CmsCategoriesList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/categories/:id" component={CategoryEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/categories/:id/:langCode" component={CategoryEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/comments" component={CommentsList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/comments/:id" component={CommentEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/cms/media" component={CmsMediaList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/media/:id" component={MediaEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/cms/faq" component={FaqList} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/faq/:id" component={FaqEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/cms/faq/:id/:langCode" component={FaqEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/documents/own" component={OwnDocumentList} />
          <PrivateRoute isLogged={isLogged} exact path="/documents/own/:id" component={OwnDocumentEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/documents/shared-for-me" component={SharedForMeDocumentList} />
          <PrivateRoute isLogged={isLogged} exact path="/documents/shared-for-me/:id" component={SharedDocument} />

          <PrivateRoute isLogged={isLogged} exact path="/documents/categories" component={KnowledgeCategoryList} />
          <PrivateRoute isLogged={isLogged} exact path="/documents/categories/:id" component={KnowledgeCategoryEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/settings/users" component={UsersList} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/users/:id" component={UserEdit} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/roles" component={RolesList} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/roles/:id" component={RoleEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/settings/groups" component={GroupView} />

          <PrivateRoute isLogged={isLogged} exact path="/settings/organizations" component={OrganizationsList} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/organizations/:id" component={OrganizationEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/settings/sms" component={SmsSettings} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/sms/:langCode" component={SmsSettings} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/mail" component={MailSettings} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/mail/:langCode" component={MailSettings} />

          <PrivateRoute isLogged={isLogged} exact path="/settings/languages" component={LanguagesList} />
          <PrivateRoute isLogged={isLogged} exact path="/settings/languages/:id" component={LanguageEdit} />

          <PrivateRoute isLogged={isLogged} exact path="/settings/log" component={LogList} />

          <PublicRoute isLogged={isLogged} exact path="/login" component={Login} />

          <PrivateRoute isLogged={isLogged} component={Page404} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
