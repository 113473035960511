import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";

import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";

import App from "./App";
import "./i18n";
import store from "./redux/store";
import AppThemeProvider from "./themes/AppThemeProvider";

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <AppThemeProvider>
          <CssBaseline />
          <Suspense fallback={<Loader />}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <App />
            </LocalizationProvider>
          </Suspense>
        </AppThemeProvider>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
