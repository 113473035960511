import React from "react";
import { useSelector } from "react-redux";

import { responsiveFontSizes, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import getTheme from "./base";

import { selectTheme } from "redux/slices/theme";

const AppThemeProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const currentTheme = useSelector(selectTheme);
  const { children } = props;
  const theme = getTheme(currentTheme);
  const themeWithResponsiveFonts = responsiveFontSizes(theme);
  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={themeWithResponsiveFonts}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppThemeProvider;
