import makeStyles from "@mui/styles/makeStyles";
import { List } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import styles from "./styles";
import React from "react";

const useStyles = makeStyles(createStyles(styles));

const SubmenuUl = (props) => {
  const classes = useStyles();
  return (
    <List className={`${classes.dropdown} ${props.className}`}>
      <span className={classes.name}>{props.name.toUpperCase()}</span>
      {props.children}
    </List>
  );
};

export default SubmenuUl;
