import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changeLanguage,
  fetchAvailableLangs,
  fetchSelectedLang,
  selectIsPolishOnly,
  selectLangCode,
  selectLanguages,
  setCurrentLanguageCode,
} from "redux/slices/language";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ListItemIcon } from "@mui/material";

const HeaderSelectLanguage = ({ itemData }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSelectedLang());
    dispatch(fetchAvailableLangs());
  }, []);

  const languages = useSelector(selectLanguages);
  const selectedLanguage = useSelector(selectLangCode);
  const isPolishOnly = useSelector(selectIsPolishOnly);

  const [sortedLanguage, setSortedLanguage] = useState<
    { sortOrder: number; code: string; name: string; flag: string }[]
  >([]);

  useEffect(() => {
    if (languages) {
      setSortedLanguage(
        [...languages].sort((a, b) => {
          return a.sortOrder - b.sortOrder;
        }),
      );
    }
  }, [languages]);

  return (
    <Select
      labelId="language-select-label"
      id="language-select"
      sx={isPolishOnly ? { opacity: 0.6, pointerEvents: "none" } : {}}
      value={isPolishOnly ? "pl" : selectedLanguage}
      onChange={
        isPolishOnly
          ? () => {
              console.info("run");
            }
          : (e) => {
              const lang = e.target.value;
              changeLanguage(lang).then((r) => {
                if (r === true) {
                  dispatch(setCurrentLanguageCode(lang));
                }
              });
            }
      }
      readOnly={itemData.disabled || isPolishOnly}
    >
      {sortedLanguage?.map((lang) => (
        <MenuItem key={lang.code} value={lang.code}>
          <ListItemIcon sx={{ minWidth: 37, height: 16 }}>
            <img alt={lang.name} src={"/flag/" + lang.flag} />
          </ListItemIcon>
          {lang.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default HeaderSelectLanguage;
