export const WS = {
  ECHO: "ECHO",
  GET_CHAT: "GET_CHAT",
  NEW_MESSAGE: "NEW_MESSAGE",
  ANSWER: "ANSWER",
  MESSAGE_START: "MESSAGE_START",
  MESSAGE_TOKEN_0: "MESSAGE_TOKEN_0",
  MESSAGE_END_AGENT: "MESSAGE_END_AGENT",
  MESSAGE_TOOL_NAME: "MESSAGE_TOOL_NAME",
  QUESTION_INTENT: "QUESTION_INTENT",
};
