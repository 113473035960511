import React, { FC } from "react";

import TableCell from "@mui/material/TableCell";
import { Avatar } from "@mui/material";

import getImage from "utils/imagesUrl";
type CellMiniatureProps = {
  data: string;
  handleClick?: (e: React.MouseEvent<HTMLElement>, row: number) => void;
  row?: number;
};
const CellMiniature: FC<CellMiniatureProps> = ({ data, handleClick, row }) => {
  return (
    <TableCell onClick={(e) => (handleClick && row ? handleClick(e, row) : null)}>
      <Avatar src={getImage(data)} sx={{ width: 60, height: 40, borderRadius: 0 }} />
    </TableCell>
  );
};

export default CellMiniature;
