import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

import { Grid, Paper } from "@mui/material";

import CustomizedTextField from "components/Edit/CustomizedTextField";

import EditViewNoLanguage from "components/EditViewNoLanguage";
import CustomizedImagePicker from "components/Edit/CustomizedImagePicker";

const endpoint = "/organizations/";
const title = "settings_organization_edit_label";
const moduleUrl = "/settings/organizations/";

const OrganizationEdit: FC = () => {
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        preview={false}
      >
        <FormData />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = ({}) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <CustomizedTextField label={t("name")} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomizedTextField label={t("short_name")} name={"shortName"} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomizedImagePicker nameUrl={"logo"} nameAlt={"alt"} label={t("logo")} placeholder={t("logo")} />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  Moduły
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <RoleModulePermision id={id} />
              </AccordionDetails>
            </Accordion>
          </Grid> */}
        </Grid>
      </Paper>
      {/*<Paper sx={{p: 10, mb: 10}} elevation={8}>*/}
      {/*    <Accordion square>*/}
      {/*        <AccordionSummary*/}
      {/*            expandIcon={<ExpandMoreIcon/>}*/}
      {/*            aria-controls="panel1a-content"*/}
      {/*            id="panel1a-header"*/}
      {/*            sx={{p: 0}}*/}
      {/*        >*/}
      {/*            <Typography variant="h4" component={"h4"}>Funkcje</Typography>*/}
      {/*        </AccordionSummary>*/}
      {/*        <AccordionDetails sx={{p: 0, mb: 5}}>*/}
      {/*            <RoleFunctionPermision*/}
      {/*                error={errors}*/}
      {/*                register={register}*/}
      {/*            />*/}
      {/*        </AccordionDetails>*/}
      {/*    </Accordion>*/}
      {/*</Paper>*/}
    </>
  );
};

export default OrganizationEdit;
