import React, { FC } from "react";

import { Button, Grid } from "@mui/material";

import * as color from "assets/styles/jss/common/colors";
import { useTranslation } from "react-i18next";
type EditActionButtonsProps = {
  handleSave: () => void;
  handleDelete: () => void;
  handlePreview?: () => void;
  handleTest?: () => void;
  noDeleteButton?: boolean;
  noSaveButton?: boolean;
  handleSetDefaultProcess?: () => void;
};
const EditActionButtons: FC<EditActionButtonsProps> = ({
  handleSave,
  handleDelete,
  handlePreview,
  handleTest,
  noDeleteButton,
  noSaveButton,
  handleSetDefaultProcess,
}) => {
  const { t } = useTranslation("common");
  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        {!noDeleteButton && (
          <Button variant="outlined" color="primary" onClick={handleDelete} sx={{ color: color.red }}>
            {t("delete")}
          </Button>
        )}
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        {handlePreview && (
          <Button variant="outlined" color="primary" onClick={handlePreview} sx={{ mr: 3 }}>
            {t("preview")}
          </Button>
        )}
        {handleTest && (
          <Button variant="outlined" color="primary" onClick={handleTest} sx={{ mr: 3 }}>
            {t("submit_test")}
          </Button>
        )}
        {handleSetDefaultProcess && (
          <Button variant="outlined" color="primary" onClick={handleSetDefaultProcess} sx={{ mr: 3 }}>
            {t("set_as_default_and_save")}
          </Button>
        )}
        {!noSaveButton && (
          <Button variant="contained" color="primary" onClick={handleSave}>
            {t("save")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default EditActionButtons;
