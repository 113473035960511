import React, { FC, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import Dialog from "@mui/material/Dialog";
import { Button, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Stack from "@mui/material/Stack";

import CategoryEditListPanel from "modules/settings/CMSDocuments/CategoryEditListPanel";

type CategoryEditListDialogControledProps = {
  open: boolean;
  handleClose: (data: any) => void;
  allItemsTable: any;
  nameFormTables: string;
  nameToDisplay: string;
  keepSortOrder?: boolean;
  setFieldsToRightColumnFromAllItems?: boolean;
};
const CategoryEditListDialogControled: FC<CategoryEditListDialogControledProps> = ({
  handleClose,
  open,
  allItemsTable,
  nameFormTables,
  nameToDisplay,
  keepSortOrder = false,
  setFieldsToRightColumnFromAllItems = false,
}) => {
  const [isDuplicateRemoved, setIsDuplicateRemoved] = useState(false);

  const [leftCheckedId, setLeftCheckedId] = useState(-1);
  const [leftArray, setLeftArray] = useState(allItemsTable);
  const [leftFilteredArray, setLeftFilteredArray] = useState(allItemsTable);
  const [leftFilter, setLeftFilter] = useState("");

  const { control, setValue } = useFormContext<{
    [nameFormTables: string]: any[];
  }>();
  const { fields, append, remove } = useFieldArray({
    name: nameFormTables,
    control, // control props comes from useForm (optional: if you are using FormContext)
    keyName: "idUseField",
  });

  const [rightCheckedId, setRightCheckedId] = useState(-1);
  const [rightArray, setRightArray] = useState<any[]>([]);
  const [rightFilteredArray, setRightFilteredArray] = useState<any[]>([]);
  const [rightFilter, setRightFilter] = useState("");

  useEffect(() => {
    setLeftArray([...allItemsTable]);
  }, [allItemsTable]);

  useEffect(() => {
    filtrationLeftArray();
  }, [leftFilter, leftArray]);

  useEffect(() => {
    filtrationRightArray();
  }, [rightFilter, rightArray]);

  useEffect(() => {
    if (open) {
      setRightArray([...fields]);
      removeDuplicateItem();
    }
  }, [open]);

  useEffect(() => {
    if (!isDuplicateRemoved && allItemsTable?.length > 0 && fields?.length > 0) {
      if (setFieldsToRightColumnFromAllItems) {
        const newFields: any[] = [];
        for (const item of fields) {
          const searchItem = allItemsTable.filter((x) => x.id === item.id);
          if (searchItem && searchItem.length > 0) {
            newFields.push({ ...item, ...searchItem[0] });
          } else {
            newFields.push({ ...item, [nameToDisplay]: "?" });
          }
        }

        setValue(nameFormTables, newFields);
      }

      removeDuplicateItem();
      setRightArray([...fields]);
      setIsDuplicateRemoved(true);
    }
  }, [allItemsTable, fields]);

  const handleSubmit = () => {
    handleClose({ isListChanged: true });

    if (keepSortOrder === true) {
      //clearList
      for (let i = fields.length - 1; i >= 0; i--) {
        remove(i);
      }

      //add all items
      for (const index in rightArray) {
        append(rightArray[index]);
      }
    } else {
      /* DEFAULT - QUICK*/
      //remove removed item
      for (let index = fields.length - 1; index >= 0; index--) {
        if (!rightArray.some((e) => e.id === fields[index].id)) {
          remove(index);
        }
      }

      //add added item
      for (const index in rightArray) {
        if (!fields.some((e) => e.id === rightArray[index].id)) {
          append(rightArray[index]);
        }
      }
    }
  };

  const removeDuplicateItem = () => {
    let tableTmp = [...allItemsTable];

    for (const rightItem of fields) {
      tableTmp = tableTmp.filter((item) => item.id !== rightItem.id);
    }
    setLeftArray([...tableTmp]);
  };

  const handleAllRight = () => {
    setRightArray(rightArray.concat(leftFilteredArray));

    let newArray = [...leftArray];
    for (const element of leftFilteredArray) {
      newArray = newArray.filter((item) => item.id !== element.id);
    }

    setLeftArray(newArray);
  };

  const handleAllLeft = () => {
    setLeftArray(leftArray.concat(rightFilteredArray));

    let newArray = [...rightArray];
    for (const element of rightFilteredArray) {
      newArray = newArray.filter((item) => item.id !== element.id);
    }

    setRightArray(newArray);
  };

  const handleCheckedRight = () => {
    if (leftFilteredArray.some((item) => item.id === leftCheckedId)) {
      setRightArray(rightArray.concat(leftArray.filter((item) => item.id === leftCheckedId)));
      setLeftArray(leftArray.filter((item) => item.id !== leftCheckedId));
      setRightCheckedId(leftCheckedId);
      setLeftCheckedId(-1);
    }
  };

  const handleCheckedLeft = () => {
    if (rightFilteredArray.some((item) => item.id === rightCheckedId)) {
      setLeftArray(leftArray.concat(rightArray.filter((item) => item.id === rightCheckedId)));
      setRightArray(rightArray.filter((item) => item.id !== rightCheckedId));
      setLeftCheckedId(rightCheckedId);
      setRightCheckedId(-1);
    }
  };

  const filtrationLeftArray = () => {
    const newArray = leftArray?.filter((item) =>
      item[nameToDisplay]?.toLowerCase().includes(leftFilter?.toLowerCase()),
    );
    setLeftFilteredArray(newArray);
  };

  const filtrationRightArray = () => {
    const newArray = rightArray.filter((item) =>
      item[nameToDisplay]?.toLowerCase().includes(rightFilter?.toLowerCase()),
    );
    setRightFilteredArray(newArray);
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        disablePortal
        onClose={() => handleClose({ isListChanged: false })}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant={"h4"} sx={{ textAlign: "center" }}>
              Edytuj listę
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <CategoryEditListPanel
              checkedId={leftCheckedId}
              setCheckedId={setLeftCheckedId}
              arrayItems={leftFilteredArray}
              setFilter={setLeftFilter}
              nameToDisplay={nameToDisplay}
              namePanel={"left-" + nameFormTables}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="column" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mt: "55px" }}>
              <IconButton onClick={handleAllRight}>
                <KeyboardDoubleArrowRightIcon />
              </IconButton>
              <IconButton onClick={handleCheckedRight}>
                <KeyboardArrowRightIcon />
              </IconButton>
              <IconButton onClick={handleAllLeft}>
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
              <IconButton onClick={handleCheckedLeft}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <CategoryEditListPanel
              checkedId={rightCheckedId}
              setCheckedId={setRightCheckedId}
              arrayItems={rightFilteredArray}
              setFilter={setRightFilter}
              nameToDisplay={nameToDisplay}
              namePanel={"right-" + nameFormTables}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleClose({ isListChanged: false });
              }}
            >
              Zamknij
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Zapisz
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
export default CategoryEditListDialogControled;
