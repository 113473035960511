import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { Button, Grid, InputLabel, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

import { selectListIsLoading } from "redux/slices/crud";

import Wysiwyg from "components/Edit/Wysiwyg";
import TabPanel from "components/Edit/CustomizedTabPanel";
import ConfirmationDialog from "components/ConfirmationDialog";
import CustomizedTabsWithDelControled from "components/CustomizedTabsWithDelControled";

const ContentsTabsPanel = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [openedRemoveDialog, setOpenedRemoveDialog] = useState(false);
  const [indexTabToDelete, setIndexTabToDelete] = useState<number | null>(null);
  const [newTabId, setNewTabId] = useState(-1);
  const [fieldsIsToSort, setFieldsIsToSort] = useState(true);
  const { register, control, formState } = useFormContext<{
    tabs: { id: number; name: string; content: string; sortOrder: number }[];
  }>();
  const isLoading = useSelector(selectListIsLoading);
  const { fields, append, remove } = useFieldArray({
    name: "tabs",
    control, // control props comes from useForm (optional: if you are using FormContext)
    keyName: "useFormkey",
  });

  //sort
  useEffect(() => {
    if (fieldsIsToSort && fields.length > 0) {
      const tmpFields = [...fields];
      tmpFields.sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });
      remove();
      append(tmpFields);
    }
    setFieldsIsToSort(false);
  }, [fields]);

  useEffect(() => {
    setFieldsIsToSort(true);
  }, [isLoading]);

  const handleAddTab = () => {
    if (fields.length === 0) {
      setActiveTab(0);
    }

    let nextSortOrder = 0;

    if (fields.length > 0) {
      nextSortOrder = Math.max(...fields.map((item) => item.sortOrder)) + 1;
    }

    append({ id: newTabId, name: "Nowa zakładka", content: "", sortOrder: nextSortOrder });

    setNewTabId(newTabId - 1);
  };

  const handleOpenRemoveDialog = (tabToDeleteIndex) => {
    setIndexTabToDelete(tabToDeleteIndex);
    setOpenedRemoveDialog(true);
  };

  const handleRemoveTab = () => {
    remove(indexTabToDelete || undefined);

    if (indexTabToDelete && indexTabToDelete <= activeTab && activeTab !== 0) {
      setActiveTab((prevState) => prevState - 1);
    }

    setOpenedRemoveDialog(false);
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" size="small" onClick={handleAddTab}>
            Dodaj zakładkę
          </Button>
        </Grid>
        {fields?.length > 0 ? (
          <Grid item xs={12}>
            <Typography>Brak zakładek</Typography>
          </Grid>
        ) : (
          !fieldsIsToSort && (
            <Grid item xs={12}>
              <CustomizedTabsWithDelControled
                tabsList={fields}
                activeTab={activeTab}
                handleChange={(event, newValue) => {
                  setActiveTab(newValue);
                }}
                handleDel={handleOpenRemoveDialog}
              />
            </Grid>
          )
        )}
      </Grid>

      {!fieldsIsToSort &&
        fields.map((field, index) => (
          <TabPanel key={field.id} value={activeTab} index={index}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <InputLabel htmlFor={`tabs.${index}.name`}>{"Nazwa zakładki"}</InputLabel>
                <TextField
                  {...register(`tabs.${index}.name`)}
                  key={field.id} // important to include key with field's id
                  helperText={
                    formState?.errors[`tabs.${index}.name`] ? formState?.errors[`tabs.${index}.name`].message : ""
                  }
                  error={!!formState?.errors[`tabs.${index}.name`]}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Wysiwyg label={"Treść strony"} name={`tabs.${index}.content`} />
              </Grid>
            </Grid>
          </TabPanel>
        ))}
      <ConfirmationDialog
        open={openedRemoveDialog}
        close={() => setOpenedRemoveDialog(false)}
        confirm={handleRemoveTab}
      />
    </>
  );
};

export default ContentsTabsPanel;
