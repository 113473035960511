import React, { FC, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

import { Grid, Paper, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";

import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import EditViewLanguage from "components/EditViewLanguage";

const endpoint = "/mail-setting/";
const title = "settings_mail_settings_label";
const moduleUrl = "/settings/mail/";
const defaultValue = {
  senderName: "",
  senderMail: "",
  encryption: "SSL",
  smtpAuthentication: true,
  smtpHost: "",
  smtpPort: 587,
  smtpUserName: "",
  smtpPassword: "",
};
const MailEdit: FC = () => {
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    senderName: Yup.string().nullable(),
    senderMail: Yup.string().email(t("form-validation-mail")).nullable(),
    encryption: Yup.string().trim().required(t("form-validation-required")),
    smtpAuthentication: Yup.bool().required(t("form-validation-required")),
    smtpHost: Yup.string().trim().nullable(),
    smtpPort: Yup.number()
      .typeError(t("form-validation-number"))
      .positive(t("form-validation-number"))
      .integer(t("form-validation-number")),
    smtpUserName: Yup.string().trim().nullable(),
    smtpPassword: Yup.string().trim().nullable(),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        noDeleteButton={true}
        noBackButton={true}
        itemWithoutId={true}
        defaultValue={defaultValue}
      >
        <FormData />
      </EditViewLanguage>
    </>
  );
};

const FormData = () => {
  const { control } = useFormContext();
  const { t } = useTranslation("common");
  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4" component={"h4"}>
              {t("sender_settings")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={t("sender_name")} name={"senderName"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={"Email"} name={"senderMail"} />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4" component={"h4"}>
              {t("smtp_settings")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12}>
                  <FormControl>
                    <FormLabel>{t("Encryption")}</FormLabel>
                    <Controller
                      control={control}
                      name="encryption"
                      defaultValue={defaultValue["encryption"]}
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel value="NONE" control={<Radio />} label={t("smtp_none")} />
                          <FormControlLabel value="SSL" control={<Radio />} label="SSL" />
                          <FormControlLabel value="TLS" control={<Radio />} label="TLS" />
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <CustomizedSwitch name={"smtpAuthentication"} rightLabel={t("authentication")} topLabel={""} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <CustomizedTextField label={"Host"} name={"smtpHost"} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomizedTextField label={"Port"} name={"smtpPort"} type={"number"} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomizedTextField label={t("smtp_user_name")} name={"smtpUserName"} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CustomizedTextField label={t("smtp_user_password")} name={"smtpPassword"} type={"password"} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default MailEdit;
