const leftPanelBackground = "#2e3038";
const leftPanelItemButton = "#353942";
const leftPanelItemButtonActive = "#434b55";
const leftPanelItemButtonHover = "#3b3f49";
const leftPanelAddButton = "#ea612c";
const leftPanelstatusBarOpen = "#4dcd95";
const leftPanelstatusBarClose = "#cd4d4d";

const mainPanelBackground = "#1c1e20";
const mainPanelQuestion = "#1c1e20";
const mainPanelAnswer = "#2e3038";
const mainPanelInput = "#ea612c";

const rightPanelBackground = "#2e3038";

const white = "#ffffff";
const gray = "#6b7282";
const orange = "#ea612c";
const pink = "#df3079";

export {
  leftPanelstatusBarOpen,
  leftPanelstatusBarClose,
  leftPanelBackground,
  leftPanelAddButton,
  leftPanelItemButton,
  leftPanelItemButtonActive,
  leftPanelItemButtonHover,
  mainPanelBackground,
  mainPanelQuestion,
  mainPanelAnswer,
  mainPanelInput,
  rightPanelBackground,
  white,
  gray,
  orange,
  pink,
};
