import { Typography } from "@mui/material";
import Layout from "layout/Layout";
import React from "react";

const FaqPage = () => {
  return (
    <Layout hiddenMenu>
      <Typography variant="h1">FAQ</Typography>
    </Layout>
  );
};
export default FaqPage;
