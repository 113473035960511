import * as color from "assets/styles/jss/common/colors";

const layoutStyle = {
  layoutContainer: {
    display: "flex",
    marginTop: "25px",
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  } as any,
  main: {
    with: "100%",
    flexGrow: 1,
    padding: "0 4vh 4vh 4vh",
    "@media (max-width: 960px)": {
      padding: "0 2vh 4vh 2vh",
    },
    background: color.lightGray,
  } as any,
  drawerModal: {
    zIndex: "99999 !important",
    top: "65px !important",
  },
};

export default layoutStyle;
