import { leftPanelItemButton, leftPanelItemButtonActive, leftPanelItemButtonHover, white, gray } from "assets/colors";
import React, { FC, memo } from "react";
import { Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { alpha } from "@mui/material/styles";
import { ShortChatType } from "modules/KnowledgeBase/types/types";

const chatButtonSx = {
  background: leftPanelItemButton,
  "&:hover": { background: leftPanelItemButtonHover },
  marginTop: "2px",
  cursor: "pointer",
  transition: "all 0.1s ease-in-out",
};

type ChatBoxProps = {
  chats: ShortChatType[];
  selectedChat: string;
  handleChangeChat: (chat: ShortChatType) => void;
  handleDeleteChat: (chat: ShortChatType, e: any) => void;
};

const ChatBox: FC<ChatBoxProps> = memo(function ChatBox({ chats, selectedChat, handleChangeChat, handleDeleteChat }) {
  return (
    <Grid container spacing={0}>
      {chats.map((chat) => (
        <Grid
          key={chat.uuid}
          item
          xs={12}
          textAlign="left"
          sx={
            selectedChat === "" + chat.uuid
              ? { ...chatButtonSx, background: leftPanelItemButtonActive, py: 3 }
              : { ...chatButtonSx, py: 1.5 }
          }
          onClick={() => handleChangeChat(chat)}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ color: white, fontSize: "1.2rem", ml: "1rem" }}>
              {chat.name}
              <Typography sx={{ color: gray, fontSize: "0.8rem" }}>{chat.date}</Typography>
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={(e) => handleDeleteChat(chat, e)}>
                <DeleteOutlineIcon sx={{ color: alpha(white, 0.4), fontSize: "1.0rem" }} />
              </IconButton>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
});

export default ChatBox;
