import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Checkbox, Tooltip, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";

import backend from "api/backend";

import { isSuccess } from "utils/http";
import { useTranslation } from "react-i18next";

const RoleModulePermission = ({ id }) => {
  const { t } = useTranslation("common");
  const { setValue } = useFormContext();
  const [menuItems, setMenuItems] = useState<{ id: number; name: string; group: any[] }[]>([]);
  const [permissions, setPermissions] = useState<{ menuItemId: number }[]>([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
    async function fetchData() {
      const res = await backend.get("/roles/permission/" + id);
      if (isSuccess(res)) {
        setPermissions(res.data);
      }
      const resMenu = await backend.get("/menu/all");
      if (isSuccess(resMenu)) {
        setMenuItems(resMenu.data);
        console.info(resMenu.data);
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setValue("permissions", permissions);
  }, [permissions]);

  const handleCheckbox = (e, m, p) => {
    const b = e.target.checked;
    const res = permissions.filter((p) => p.menuItemId === m);
    let permission;
    if (res?.length > 0) {
      permission = res[0];
    } else {
      permission = { menuItemId: m };
    }
    permission[p] = b;
    setPermissions([...permissions.filter((p) => p.menuItemId !== m), permission]);
  };

  const isClicked = (m, p) => {
    const res = permissions.filter((p) => p.menuItemId === m);
    if (res?.length > 0) {
      const permission = res[0][p];
      if (permission) return permission;
    }
    return false;
  };

  function renderItem(m) {
    return (
      <TableRow key={`ri.${m.id}`}>
        <TableCell>{m.title}</TableCell>
        <TableCell>
          <Checkbox
            color="primary"
            onClick={(e) => handleCheckbox(e, m.id, "visibility")}
            inputProps={{ "aria-label": "secondary checkbox" }}
            checked={isClicked(m.id, "visibility")}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            color="primary"
            onClick={(e) => handleCheckbox(e, m.id, "creating")}
            checked={isClicked(m.id, "creating")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            color="primary"
            onClick={(e) => handleCheckbox(e, m.id, "read")}
            checked={isClicked(m.id, "read")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            color="primary"
            onClick={(e) => handleCheckbox(e, m.id, "write")}
            checked={isClicked(m.id, "write")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            color="primary"
            checked={isClicked(m.id, "delete")}
            onClick={(e) => handleCheckbox(e, m.id, "delete")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={{ width: 60, textAlign: "center", pb: 4 }}>
                <Tooltip title={t("roles_visibilit_tooltip")} placement="top" arrow={true} sx={{ mr: "10px" }}>
                  <Typography variant={"h5"}>V</Typography>
                </Tooltip>
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: "center", pb: 4 }}>
                <Tooltip title={t("roles_create_tooltip")} placement="top" arrow={true} sx={{ mr: "10px" }}>
                  <Typography variant={"h5"}>C</Typography>
                </Tooltip>
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: "center", pb: 4 }}>
                <Tooltip title={t("roles_read_tooltip")} placement="top" arrow={true} sx={{ mr: "10px" }}>
                  <Typography variant={"h5"}>R</Typography>
                </Tooltip>
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: "center", pb: 4 }}>
                <Tooltip title={t("roles_update_tooltip")} placement="top" arrow={true} sx={{ mr: "10px" }}>
                  <Typography variant={"h5"}>U</Typography>
                </Tooltip>
              </TableCell>
              <TableCell sx={{ width: 60, textAlign: "center", pb: 4 }}>
                <Tooltip title={t("roles_delete_tooltip")} placement="top" arrow={true} sx={{ mr: "10px" }}>
                  <Typography variant={"h5"}>D</Typography>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {menuItems.map((m) => {
              return (
                <React.Fragment key={`rc.${m.id}`}>
                  <TableRow key={`mi.${m.id}`}>
                    <TableCell sx={{ fontWeight: 800 }}>{m.name}</TableCell>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        onClick={(e) => handleCheckbox(e, m.id, "visibility")}
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        checked={isClicked(m.id, "visibility")}
                      />
                    </TableCell>
                    <TableCell colSpan={4}></TableCell>
                  </TableRow>
                  {m.group?.map((g) => g.pages?.map((p) => renderItem(p)))}
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
};
export default RoleModulePermission;
