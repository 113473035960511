import React, { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import Box from "@mui/material/Box";
import { alpha } from "@mui/material/styles";
import { Card, CardMedia, InputLabel, Typography } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";

import MediaDialog from "modules/settings/CMSResources/MediaDialog";
import backend from "api/backend";

import { isSuccess } from "utils/http";
import getImage from "utils/imagesUrl";

import * as color from "assets/styles/jss/common/colors";

const Details = (detailsImage, url) => {
  return (
    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
      {detailsImage?.name && (
        <Typography variant={"body2"} color={color.black}>
          {detailsImage?.name}
        </Typography>
      )}
      {detailsImage?.fileSize && (
        <Typography variant={"body2"} color={color.black}>
          {Math.round(detailsImage?.fileSize / 1024) + " kb"}
        </Typography>
      )}
      {detailsImage?.dimensions && (
        <Typography variant={"body2"} color={color.black}>
          {detailsImage?.dimensions}
        </Typography>
      )}
      {url && !detailsImage?.name && !detailsImage?.fileSize && !detailsImage?.fileSize && (
        <Typography variant={"body2"} color={color.black}>
          {url}
        </Typography>
      )}
    </Stack>
  );
};

type CustomizedImagePickerProps = {
  label: string;
  placeholder: string;
  nameUrl?: string;
  nameAlt?: string;
  image?: string;
  setImage?: (image: string) => void;
};
const CustomizedImagePicker: FC<CustomizedImagePickerProps> = ({
  label,
  placeholder,
  nameUrl = "",
  nameAlt = "",
  image,
  setImage = undefined,
}) => {
  const [isMediaDialogOpen, setMediaDialogOpen] = useState(false);
  const [detailsStack, setDetailsStack] = useState<React.ReactNode | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { watch } = useFormContext();
  const watchUrl = watch(nameUrl, "");

  useEffect(() => {
    if (image) {
      setIsLoading(true);
      const data = { url: image };
      backend.post("/media/getDetails", data).then((res) => {
        if (isSuccess(res)) {
          if (res.data !== null) {
            setDetailsStack(Details(res.data, image));
          } else {
            setDetailsStack(Details(null, image));
          }
        }
        setIsLoading(false);
      });
    } else {
      setDetailsStack("");
    }
  }, [image]);

  useEffect(() => {
    if (watchUrl?.length > 0) {
      setIsLoading(true);
      const data = { url: watchUrl };
      backend.post("/media/getDetails", data).then((res) => {
        if (isSuccess(res)) {
          if (res.data !== null) {
            setDetailsStack(Details(res.data, watchUrl));
          } else {
            setDetailsStack(Details(null, watchUrl));
          }
        }
        setIsLoading(false);
      });
    } else if (!image) {
      setDetailsStack("");
    }
  }, [watchUrl]);

  return (
    <>
      {isMediaDialogOpen && (
        <MediaDialog
          nameUrl={nameUrl}
          nameAlt={nameAlt}
          open={isMediaDialogOpen}
          close={() => setMediaDialogOpen(false)}
          setImage={setImage}
          image={image}
        />
      )}
      <InputLabel>{label}</InputLabel>
      <Box
        onClick={() => setMediaDialogOpen(true)}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          p: 3,
          mb: 5,
          borderWidth: 2,
          borderRadius: 2,
          borderColor: alpha(color.primary, 0.15),
          borderStyle: "dashed",
          backgroundColor: alpha(color.primary, 0.05),
          outline: "none",
          transition: "border .24s ease-in-out",
          cursor: "pointer",
          "&:hover": {
            borderColor: alpha(color.primary, 0.55),
          },
        }}
      >
        {image || watchUrl?.length > 0 ? (
          <>
            <Card sx={{ borderRadius: 1, mr: 4, maxWidth: 1 / 2 }}>
              <CardMedia component="img" height="70" image={image ? getImage(image) : getImage(watchUrl)} />
            </Card>
            <div>
              <Typography variant={"body2"} color={color.black} fontWeight={"bold"} sx={{ mb: 1 }}>
                {placeholder}
              </Typography>
              {isLoading ? <CircularProgress size={10} /> : detailsStack}
            </div>
          </>
        ) : (
          <>
            <ImageIcon fontSize={"large"} sx={{ borderRadius: 1, mr: 4 }} />
            <Typography variant={"body2"} color={color.black} fontWeight={"bold"}>
              Wybierz plik
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default CustomizedImagePicker;
