import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import clsx from "clsx";
import MenuItem from "layout/Menu/MenuItem/MenuItem";
import Submenu from "layout/Menu/Submenu/Submenu";

import { Box, Hidden, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import createStyles from "@mui/styles/createStyles";
import { selectCurrentSelectedLevelZero, setCurrentSelectedLevelZero } from "redux/slices/menu";

import styles from "./styles";

const useStyles = makeStyles<Theme>(createStyles(styles));

const Menu = ({ menuItems }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const sidebar = useSelector(selectCurrentSelectedLevelZero);
  const [mobileMain, setMobileMain] = useState(true);

  const markMenuItem = (itemIdx) => {
    const clickedIndex = parseInt(itemIdx);
    dispatch(setCurrentSelectedLevelZero(clickedIndex));
    setMobileMain(false);
  };

  const backToMain = () => {
    setMobileMain(true);
  };

  return (
    menuItems?.length > 0 && (
      <div className={classes.sidebar}>
        {/* mobile menu */}
        <Hidden mdUp>
          <nav className={clsx(classes.mobileMItems, !mobileMain && classes.visibleSubmenu)}>
            {!mobileMain && (
              <Box className={classes.back} onClick={backToMain}>
                <ChevronLeftIcon />
                {menuItems[sidebar].name}
              </Box>
            )}
            {mobileMain && (
              <>
                <ul className={classes.mobileCategories}>
                  {menuItems.map((menu, index) => (
                    <MenuItem key={"sdbar." + index} index={index} item={menu} onClickedItem={markMenuItem} />
                  ))}
                </ul>
              </>
            )}

            {!mobileMain && <Submenu group={menuItems[sidebar].group} />}
          </nav>
        </Hidden>
        {/* normal */}
        <Hidden mdDown>
          <nav>
            <div className={classes.leftSidebar}>
              {menuItems.map((menu, index) => (
                <MenuItem
                  key={"sdbar." + index}
                  index={index}
                  show={sidebar}
                  item={menu}
                  onClickedItem={markMenuItem}
                />
              ))}
            </div>
          </nav>

          <div className={classes.rightSidebar}>
            <div className={classes.logoWrapper}></div>
            <div className={classes.submenuWrapper}>
              <Submenu group={menuItems[sidebar].group} />
            </div>
          </div>
        </Hidden>
      </div>
    )
  );
};
export default Menu;
