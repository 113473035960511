import React, { memo } from "react";

import { ListItem, ListItemText } from "@mui/material";
import Divider from "@mui/material/Divider";

const CustomizedListItem = ({ primaryText, secondaryText }) => {
  return (
    <>
      <ListItem sx={{ px: 0 }}>
        <ListItemText primary={primaryText} secondary={secondaryText} />
      </ListItem>
      <Divider sx={{ my: 2 }} />
    </>
  );
};

export default memo(CustomizedListItem);
