import TableContainer from "@mui/material/TableContainer";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

type DetailsTablePropsType = {
  versions: any[];
  handleDownload: (version: number, fileExtension: string, originalName: string) => void;
  handleDelete?: (version: number) => void;
};

const DetailsTable: FC<DetailsTablePropsType> = ({ versions, handleDownload, handleDelete }) => {
  const { t } = useTranslation("common");
  const [versionToDelete, setVersionToDelete] = useState<number | null>(null);

  const handleConfirmDelete = () => {
    if (versionToDelete !== null && handleDelete) {
      handleDelete(versionToDelete);
      setVersionToDelete(null);
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "15%" }}>{t("document_own_edit_created")}</TableCell>
              <TableCell align="right" sx={{ width: "5%" }}>
                {t("document_own_edit_version")}
              </TableCell>
              <TableCell>{t("document_own_edit_original_name")}</TableCell>
              <TableCell sx={{ width: "15%" }}>{t("document_own_edit_file_extension")}</TableCell>
              <TableCell sx={{ width: "10%" }}>{t("document_own_edit_file_size")}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {versions?.map((doc) => {
              return (
                <TableRow key={doc.id}>
                  <TableCell>
                    <Box component="span" sx={{ whiteSpace: "nowrap" }}>
                      {doc.created}
                    </Box>
                  </TableCell>
                  <TableCell align="right">{doc.version}</TableCell>
                  <TableCell>{doc.originalName}</TableCell>
                  <TableCell>{doc.fileExtension}</TableCell>
                  <TableCell>{doc.fileSize ? Math.round(doc.fileSize / 1024) + " kb" : ""}</TableCell>
                  <TableCell>
                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                      {handleDelete && versions.length > 1 && (
                        <Tooltip title={t("document_own_edit_delete_version")}>
                          <IconButton onClick={() => setVersionToDelete(doc.version)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title={t("document_own_edit_get_file")}>
                        <IconButton onClick={() => handleDownload(doc.version, doc.fileExtension, doc.originalName)}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmationDialog
        close={() => setVersionToDelete(null)}
        confirm={handleConfirmDelete}
        customTitle={t("document_own_edit_delete_version_confirm_tite")}
        open={versionToDelete !== null}
      />
    </>
  );
};
export default DetailsTable;
