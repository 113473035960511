import React, { FC, Fragment, memo, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { debounce } from "throttle-debounce";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { Card, CardMedia, InputAdornment, Link, ListItem, ListItemText, Slide, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import DownloadingIcon from "@mui/icons-material/Downloading";
import ImageIcon from "@mui/icons-material/Image";

import backend from "api/backend";
import ImagePreviewDialog from "modules/settings/CMSResources/ImagePreviewDialog";

import { isSuccess } from "utils/http";
import getImage from "utils/imagesUrl";

import * as color from "assets/styles/jss/common/colors";
import { ImageType } from "types/types";

const DATA_DOWNLOAD_STEP = 20;

type MediaDialogProps = {
  open: boolean;
  close: () => void;
  nameUrl?: string;
  nameAlt?: string;
  setImage?: (url: string) => void;
  setImageAlt?: (alt: string) => void;
  image?: ImageType | string;
};
const MediaDialog: FC<MediaDialogProps> = ({ open = false, close, nameUrl, nameAlt, setImage, setImageAlt, image }) => {
  const { setValue, getValues } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [numberData, setNumberData] = useState(DATA_DOWNLOAD_STEP);
  const [openImagePrev, setOpenImagePrev] = useState(false);

  const [mediaAll, setMediaAll] = useState<ImageType[]>([]);
  const [mediaFiltered, setMediaFiltered] = useState<ImageType[]>([]);

  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [alt, setAlt] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [fileSize, setFileSize] = useState<number>(0);
  const [fileDimension, setFileDimension] = useState("");

  const [searchPhrase, setSearchPhrase] = useState("");

  const searchDebounced = debounce(500, (phrase) => setSearchPhrase(phrase));

  useEffect(() => {
    backend.get("/media/getAll").then((res) => {
      if (isSuccess(res)) {
        const numberImage = res.data.length;

        if (numberImage > 0) {
          setMediaAll(res.data);
          setSearchPhrase("");
        }

        if (numberImage < numberData) {
          setNumberData(numberImage);
        }
      }
    });
    return () => {
      setMediaAll([]);
    };
  }, []);

  useEffect(() => {
    const newMediaFiltered = mediaAll.filter((item) => {
      return (
        item?.name?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
        item?.title?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
        item?.altText?.toLowerCase().includes(searchPhrase.toLowerCase()) ||
        item?.fileExtension?.toLowerCase().includes(searchPhrase.toLowerCase())
      );
    });
    setMediaFiltered(newMediaFiltered);
  }, [searchPhrase, mediaAll]);

  useEffect(() => {
    setAlt(getValues(nameAlt || ""));
    setFileDimension("");
    setFileExtension("");
    setFileSize(0);
    setName("");
    setTitle("");
    setUrl(image ? image : getValues(nameUrl || ""));
  }, [open]);

  // const handleChangeLinked = (event, newValue) => {
  //     setActiveTabLinked(newValue);
  // };

  const handleClose = () => {
    close();
  };
  const handleSubmit = () => {
    if (setImage) {
      setImage(url);
    } else if (nameUrl) {
      setValue(nameUrl, url);
    }

    if (setImageAlt) {
      setImageAlt(alt);
    } else if (nameAlt) {
      setValue(nameAlt, alt);
    }
    close();
  };
  function handleClickMore() {
    setLoading(true);
    const nextNumberData = numberData + DATA_DOWNLOAD_STEP;
    nextNumberData > mediaAll?.length ? setNumberData(mediaAll.length) : setNumberData(nextNumberData);
    setLoading(false);
  }

  const handleChoiceImage = (image) => {
    setAlt(image.altText);
    setFileDimension(image.dimensions);
    setFileExtension(image.fileExtension);
    setFileSize(image.fileSize);
    setName(image.name);
    setTitle(image.title);
    setUrl(image.url);
  };

  const handleResetImage = () => {
    setAlt("");
    setFileDimension("");
    setFileExtension("");
    setFileSize(0);
    setName("");
    setTitle("");
    setUrl("");
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        disablePortal
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth={false}
        TransitionComponent={Slide}
        fullScreen
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          sx={{
            position: "relative",
            borderBottom: "1px solid " + color.gray,
            mb: 0 + "!important",
          }}
        >
          <Typography component={"div"} variant={"h3"} sx={{ textAlign: "center", mt: 6, mb: 4 }}>
            Wybierz obrazek
          </Typography>
          <IconButton aria-label="x-close" onClick={handleClose} sx={{ position: "absolute", top: 15, right: 0 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={8}>
            <Grid item sm={12} md container spacing={5} sx={{ my: 1 }}>
              <Grid item xs={12}>
                <TextField
                  placeholder={"szukaj"}
                  type="search"
                  variant="outlined"
                  size="small"
                  onChange={(e) => searchDebounced(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {mediaFiltered?.map((item, index) => (
                <Fragment key={item.id}>
                  {index < numberData && (
                    <Grid xs={"auto"} item>
                      <Card sx={{ borderRadius: 2, border: "1px solid", cursor: "pointer", width: 300 }}>
                        <CardMedia
                          onClick={() => handleChoiceImage(item)}
                          component="img"
                          height={200}
                          image={`${getImage(item.url)}?w=300&h=200&fit=crop&auto=format`}
                          alt={item.altText}
                        />
                      </Card>
                    </Grid>
                  )}
                </Fragment>
              ))}
              <Grid item xs={12} sx={{ mt: 8, textAlign: "center" }}>
                <Typography variant={"body2"} color={color.darkerGray}>
                  Wyświetlono {numberData} z {mediaAll?.length} elementów biblioteki
                </Typography>
                <LoadingButton
                  size="small"
                  color="primary"
                  onClick={handleClickMore}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<DownloadingIcon />}
                  variant="outlined"
                  sx={{ mb: 6, mt: 2 }}
                  disabled={mediaAll?.length <= numberData}
                >
                  Wczytaj więcej
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
              <Box sx={{ width: 370, height: "100%", borderLeft: "1px solid " + color.gray, p: 5 }}>
                <Grid container spacing={3} sx={{ mb: 6 }}>
                  <Grid item xs={12}>
                    {url?.length > 0 ? (
                      <CardMedia
                        component="img"
                        height="auto"
                        image={getImage(url)}
                        alt={alt}
                        sx={{ border: "1px solid " + color.black, cursor: "pointer" }}
                        onClick={() => setOpenImagePrev(true)}
                      />
                    ) : (
                      <ImageIcon fontSize={"large"} />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                    {/*TO DO*/}
                    {/*<Link variant={"body2"} href="#" underline="hover">*/}
                    {/*    {'Podmień'}*/}
                    {/*</Link>*/}
                    {/*<Typography component={"span"} sx={{color: color.gray, px: 4}}>|</Typography>*/}
                    <Link variant={"body2"} onClick={handleResetImage} underline="hover" color={color.red}>
                      {"Usuń"}
                    </Link>
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  {/*TO DO*/}
                  {/*<DialogTextField*/}
                  {/*    label={"Nazwa pliku"}*/}
                  {/*    name={"nameImage"}*/}
                  {/*    value={name}*/}
                  {/*    setValue={setName}*/}
                  {/*    errors={{}}*/}
                  {/*/>*/}
                  {/*<DialogTextField*/}
                  {/*    label={"Tytuł"}*/}
                  {/*    name={"titleImage"}*/}
                  {/*    value={title}*/}
                  {/*    setValue={setTitle}*/}
                  {/*    errors={{}}*/}
                  {/*/>*/}
                  {/*<DialogTextField*/}
                  {/*    label={"Tekst alternatywny"}*/}
                  {/*    name={"altImage"}*/}
                  {/*    value={alt}*/}
                  {/*    setValue={setAlt}*/}
                  {/*    errors={{}}*/}
                  {/*/>*/}
                </Grid>
                <List sx={{ width: "100%", p: 0, m: 0 }}>
                  {name?.length > 0 && (
                    <ListItem sx={{ p: 0, m: 0 }}>
                      <ListItemText
                        primary={"Nazwa pliku"}
                        secondary={name}
                        primaryTypographyProps={{
                          style: {
                            wordWrap: "break-word",
                            fontSize: "0.9rem",
                          },
                        }}
                      />
                    </ListItem>
                  )}
                  {title?.length > 0 && (
                    <ListItem sx={{ p: 0, m: 0 }}>
                      <ListItemText
                        primary={"Tytuł"}
                        secondary={title}
                        primaryTypographyProps={{
                          style: {
                            wordWrap: "break-word",
                            fontSize: "0.9rem",
                          },
                        }}
                      />
                    </ListItem>
                  )}
                  {alt?.length > 0 && (
                    <ListItem sx={{ p: 0, m: 0 }}>
                      <ListItemText
                        primary={"Tekst alternatywny"}
                        secondary={alt}
                        primaryTypographyProps={{
                          style: {
                            wordWrap: "break-word",
                            fontSize: "0.9rem",
                          },
                        }}
                      />
                    </ListItem>
                  )}
                  {url?.length > 0 && (
                    <ListItem sx={{ p: 0, m: 0 }}>
                      <ListItemText
                        primary={"URL"}
                        secondary={url}
                        primaryTypographyProps={{
                          style: {
                            wordWrap: "break-word",
                            fontSize: "0.9rem",
                          },
                        }}
                      />
                    </ListItem>
                  )}
                  {fileExtension?.length > 0 && (
                    <ListItem sx={{ p: 0, m: 0 }}>
                      <ListItemText
                        primary={"Rozszerzenie"}
                        secondary={fileExtension}
                        primaryTypographyProps={{
                          style: {
                            wordWrap: "break-word",
                            fontSize: "0.9rem",
                          },
                        }}
                      />
                    </ListItem>
                  )}
                  {fileSize !== null && fileSize > 0 && (
                    <ListItem sx={{ p: 0, m: 0 }}>
                      <ListItemText
                        primary={"Rozmiar"}
                        secondary={Math.round(fileSize / 1024) + " kb"}
                        primaryTypographyProps={{
                          style: {
                            wordWrap: "break-word",
                            fontSize: "0.9rem",
                          },
                        }}
                      />
                    </ListItem>
                  )}
                  {fileDimension?.length > 0 && (
                    <ListItem sx={{ p: 0, m: 0 }}>
                      <ListItemText
                        primary={"Wymiary"}
                        secondary={fileDimension}
                        primaryTypographyProps={{
                          style: {
                            wordWrap: "break-word",
                            fontSize: "0.9rem",
                          },
                        }}
                      />
                    </ListItem>
                  )}
                </List>
              </Box>
            </Grid>
          </Grid>
          {/*</TabPanel>*/}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
            py: 5,
            px: 0,
            borderTop: "1px solid " + color.gray,
          }}
        >
          <Button onClick={handleClose} variant="outlined" color="primary">
            Anuluj
          </Button>
          <Button color="primary" onClick={handleSubmit} variant={"contained"}>
            Wybierz
          </Button>
        </DialogActions>
      </Dialog>
      {openImagePrev && (
        <ImagePreviewDialog
          openImage={openImagePrev}
          closeImage={() => setOpenImagePrev(false)}
          imageUrl={getImage(url)}
        />
      )}
    </>
  );
};

export default memo(MediaDialog);
