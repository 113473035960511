import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Paper } from "@mui/material";

import HeaderBuilder from "layout/Header/HeaderBuilder";
import Layout from "layout/Layout";

import DataTable from "components/Table/DataTable";

const items = {
  toolbarItems: [
    {
      name: "toolbar_button_add",
      tooltip: "toolbar_button_add_tooltip",
      icon: "",
      action: "add",
      disabled: false,
      sortOrder: 0,
      type: "BUTTON",
    },
    {
      name: "toolbar_icon_copy",
      tooltip: "toolbar_icon_duplicate_tooltip",
      icon: "ContentCopy",
      action: "copy",
      disabled: false,
      sortOrder: 1,
      type: "ICON",
    },
    {
      name: "toolbar_icon_del",
      tooltip: "toolbar_icon_del_tooltip",
      icon: "Delete",
      action: "delete",
      disabled: false,
      sortOrder: 2,
      type: "ICON",
    },
    {
      name: "toolbar_search_field",
      tooltip: "toolbar_search_field_tooltip",
      icon: "Search",
      action: "search",
      disabled: false,
      sortOrder: 4,
      type: "FIELDS_SEARCH",
      searchFieldName: "name:like,login:like,email:like",
    },
  ],
  headCells: [
    {
      type: "DATA",
      id: "name",
      numeric: false,
      disablePadding: false,
      bool: false,
      date: false,
      sortable: true,
      label: "settings_user_name",
    },
    {
      type: "DATA",
      id: "mail",
      numeric: false,
      disablePadding: false,
      bool: false,
      date: false,
      sortable: true,
      label: "settings_user_mail",
    },
    {
      type: "DATA",
      id: "role",
      numeric: false,
      disablePadding: false,
      bool: false,
      date: false,
      sortable: true,
      label: "settings_user_role",
    },
    {
      type: "DATA",
      id: "date",
      numeric: false,
      disablePadding: false,
      bool: false,
      date: true,
      sortable: true,
      label: "settings_user_date",
    },
  ],
  pagination: {
    page: 0,
    limit: 15,
    order: "id",
    totalItems: 43,
    totalPages: 3,
  },
  items: [
    {
      id: 1,
      name: "Test",
      mail: "test@greenlogic.pl",
      role: "Super admin",
      date: 1613423432423,
    },
    // {
    //     "id": 2,
    //     "name": "Administrator",
    //     "mail": "tomek@greenlogic.pl",
    //     "role": "Super admin",
    //     "date": 1613423432423,
    // },
    // {
    //     "id": 3,
    //     "name": "Administrator",
    //     "mail": "tomek@greenlogic.pl",
    //     "role": "Super admin",
    //     "date": 1613423432423,
    // },
    // {
    //     "id": 4,
    //     "name": "Administrator",
    //     "mail": "tomek@greenlogic.pl",
    //     "role": "Super admin",
    //     "date": 1613423432423,
    // },
    // {
    //     "id": 5,
    //     "name": "Administrator",
    //     "mail": "tomek@greenlogic.pl",
    //     "role": "Super admin",
    //     "date": 1613423432423,
    // },
    // {
    //     "id": 6,
    //     "name": "Administrator",
    //     "mail": "tomek@greenlogic.pl",
    //     "role": "Super admin",
    //     "date": 1613423432423,
    // },
    // {
    //     "id": 7,
    //     "name": "Administrator",
    //     "mail": "tomek@greenlogic.pl",
    //     "role": "Super admin",
    //     "date": 1613423432423,
    // },
    // {
    //     "id": 8,
    //     "name": "Administrator",
    //     "mail": "tomek@greenlogic.pl",
    //     "role": "Super admin",
    //     "date": 1613423432423,
    // },
    // {
    //     "id": 9,
    //     "name": "Administrator",
    //     "mail": "tomek@greenlogic.pl",
    //     "role": "Super admin",
    //     "date": 1613423432423,
    // },
    // {
    //     "id": 10,
    //     "name": "Administrator",
    //     "mail": "tomek@greenlogic.pl",
    //     "role": "Super admin",
    //     "date": 1613423432423,
    // },
  ],
  filters: [
    {
      placeholder: "Rola",
      destFieldName: "roles.id",
      url: "/roles",
      srcFieldName: "name",
      type: "ASYNCHRONOUS",
      wide: true,
    },
  ],
};

const UsersList: FC = () => {
  const { t } = useTranslation("common");
  return (
    <Layout
      header={t("settings_users_label")}
      top={<HeaderBuilder headerData={items.toolbarItems} moduleUrl={"/settings/users"} />}
    >
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <DataTable modulePath={"/settings/users"} endpoint={"/users/"} />
      </Paper>
    </Layout>
  );
};
export default UsersList;
