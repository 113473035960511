import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";

export const fetchGroupList = createAsyncThunk("group/list", async () => {
  const res = await backend.get("/roles/");
  return res.data;
});

const groupSlice = createSlice({
  name: "groups",
  initialState: {
    isLoading: false,
    list: [],
    userGroups: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGroupList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(fetchGroupList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(fetchGroupList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
      });
  },
});

export const selectGroupList = (state) => state.groups.list;

export default groupSlice.reducer;
