import React, { FC, lazy, Suspense, useCallback, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import useLocalStorage from "use-local-storage";
import ChatLeftPanel from "modules/KnowledgeBase/components/ChatLeftPanel";
import ChatMainPanel from "modules/KnowledgeBase/components/ChatMainPanel";
import { Typography } from "@mui/material";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useHistory, useParams } from "react-router-dom";
import { WS } from "enums/ws_types";
import { useSnackbar } from "notistack";
import Typing from "modules/KnowledgeBase/components/Typing/Typing";
import fetcher from "utils/api";
import { useTranslation } from "react-i18next";
import { SettingsType, ShortCategoryType, ShortChatType } from "modules/KnowledgeBase/types/types";
import backend from "api/backend";
import { isSuccess } from "utils/http";

const DeleteChatDialog = lazy(() => import("modules/KnowledgeBase/components/dialogs/DeleteChatDialog"));
const AddNewChatDialog = lazy(() => import("modules/KnowledgeBase/components/dialogs/AddNewChatDialog"));
const SettingsDialog = lazy(() => import("modules/KnowledgeBase/components/dialogs/SettingsDialog"));

const endpoint_get_short_view_chats = "/knowledge-api/chat/short-chats";
const endpoint_get_short_view_categories = "/knowledge-category/get-categories";
const endpoint_delete_chat = "/knowledge-api/chat";
const endpoint_create_new_chat = "/knowledge-api/chat/create";

const newQuestion = (question: string | null, answer: string | React.ReactNode): any => {
  return {
    id: -1,
    message: question,
    answers: {
      id: -1,
      messages: [
        {
          id: -1,
          message_content: answer,
        },
      ],
    },
  };
};

type ChatProps = {
  isFullscreen: boolean;
  setFullscreen: (value: any) => void;
};

const Chat: FC<ChatProps> = ({ isFullscreen, setFullscreen }) => {
  const { t } = useTranslation("common");
  const [isWaitingChatResponse, setIsWaitingChatResponse] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const params: any = useParams();
  const { uuid } = params;
  const history = useHistory();

  const [categories, setCategories] = useState<ShortCategoryType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const [chats, setChats] = useState<ShortChatType[]>([]);
  const [chat, setChat] = useState<any>(null);

  const [isAddNewChatDialogOpen, setIsAddNewChatDialogOpen] = useState<boolean>(false);
  const [isDeleteChatDialogOpen, setIsDeleteChatDialogOpen] = useState<boolean>(false);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState<boolean>(false);
  const [chatToDelete, setChatToDelete] = useState<any>(false);

  const [model, setModel] = useLocalStorage<string>("chat_model", "gpt-4-1106-preview");
  const [temperature, setTemperature] = useLocalStorage<number>("chat_temperature", 0.1);
  const [maxTokens, setMaxTokens] = useLocalStorage<number>("chat_max_tokens", 400);

  const [lastQuestion, setLastQuestion] = useState(null);
  const [lastAnswer, setLastAnswer] = useState<any>(null);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(`${process.env.REACT_APP_KNOWLEDGE_WS_URL}`, {
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    // attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
    reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  const sendMessageWs = async (uuid: string, message: string) => {
    setIsWaitingChatResponse(true);

    const d = {
      type: WS.NEW_MESSAGE,
      message: message,
      model: model,
      temperature: temperature,
      maxTokens: maxTokens,
      uuid: uuid,
      knowledgeModel: selectedCategory,
    };

    wsSendJson(d);
  };

  const handleSendMessage = async (message, newMessageTextFieldRef) => {
    if (isWaitingChatResponse) {
      enqueueSnackbar(t("knowledge_base_wait"), { variant: "warning" });
      return;
    }

    newMessageTextFieldRef.current.value = "";
    setLastQuestion(message);
    setLastAnswer(<Typing />);

    setChat((prev) => ({
      ...prev,
      questions: [...prev.questions, newQuestion(message, <Typing />)],
    }));

    sendMessageWs(uuid, message);
  };

  const getChats = async () => {
    try {
      const result = await fetcher(endpoint_get_short_view_chats);
      if (result?.data) {
        setChats(result.data);
      }
    } catch (error) {
      enqueueSnackbar(t("knowledge_base_connection_error"), { variant: "error" });
    }
  };

  useEffect(() => {
    const getCategories = () => {
      backend
        .get(endpoint_get_short_view_categories)
        .then((res) => {
          if (isSuccess(res) && res.data) {
            setCategories(res.data);
          }
        })
        .catch((reason) => {
          setCategories([]);
          console.error(reason);
        });
    };

    getCategories();
  }, []);

  useEffect(() => {
    async function getData() {
      if (uuid) {
        await getChatWs(uuid);
      }
      await getChats();
    }
    getData();
  }, [uuid]);

  const callHandleChangeChat = useCallback(
    (chat) => {
      if (isWaitingChatResponse) {
        enqueueSnackbar(t("knowledge_base_wait"), { variant: "warning" });
        return;
      }

      history.push(`/knowledge-base/${chat.uuid}`);
    },
    [uuid, isWaitingChatResponse],
  );

  const addNewChat = async (language) => {
    try {
      const result = await fetcher(endpoint_create_new_chat, { lang: language }, "POST");
      if (result?.data) {
        history.push(`/knowledge-base/${result.data.uuid}`);
      }
    } catch (error) {
      enqueueSnackbar(t("knowledge_base_connection_error"), { variant: "error" });
    } finally {
      setIsAddNewChatDialogOpen(false);
    }
  };

  const saveSettings = async (settings: SettingsType) => {
    setModel(settings.model);
    setTemperature(settings.temperature);
    setMaxTokens(settings.max_tokens);

    setIsSettingsDialogOpen(false);
  };

  const handleDeleteChat = (chat, e) => {
    e.stopPropagation();
    setIsDeleteChatDialogOpen(true);
    setChatToDelete(chat);
  };
  const deleteChat = async () => {
    try {
      const result = await fetcher(`${endpoint_delete_chat}/${chatToDelete.uuid}`, {}, "delete");
      if (result?.status === "ok") {
        enqueueSnackbar(t("knowledge_base_conversation_deleted"), { variant: "success" });
      } else {
        enqueueSnackbar(t("knowledge_base_error_while_deleting"), { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(t("knowledge_base_connection_error"), { variant: "error" });
    } finally {
      await getChats();
      setIsDeleteChatDialogOpen(false);
      if (uuid === chatToDelete.uuid) {
        history.push(`/knowledge-base/`);
      }
    }
  };

  const getChatWs = (uuid) => {
    wsSendJson({ type: WS.GET_CHAT, uuid: uuid });
  };

  const printAnswer = () => {
    if (lastAnswer === null) {
      return;
    }

    setChat((prev) => {
      const q = prev.questions;
      const sizeQuestions = q.length;
      if (q[sizeQuestions - 1].id === -1) {
        q[sizeQuestions - 1] = newQuestion(lastQuestion, lastAnswer);
      }
      prev.questions = [...q];

      return { ...prev };
    });
  };

  useEffect(() => {
    printAnswer();
  }, [lastAnswer]);

  const removeLastQuestionAfterError = () => {
    setChat((prev) => {
      const q = prev.questions.filter((item) => {
        return item.id !== -1;
      });

      return { ...prev, questions: q };
    });
  };

  useEffect(() => {
    if (lastJsonMessage) {
      const type = lastJsonMessage["type"];
      const message = lastJsonMessage["message"];
      const errorMessage = lastJsonMessage["error_message"];

      switch (type) {
        case WS.GET_CHAT:
          if (errorMessage) {
            enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 20000 });
          } else {
            setChat(lastJsonMessage["data"]);
          }
          break;
        case WS.MESSAGE_START:
          setLastAnswer("");

          break;
        case WS.MESSAGE_TOKEN_0:
          setLastAnswer((prev) => (prev += message));

          break;
        case WS.ANSWER:
          const question = lastJsonMessage["question"];
          const logs = lastJsonMessage["logs"];

          if (errorMessage) {
            enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 20000 });
            removeLastQuestionAfterError();
          } else {
            setChat((prev) => {
              const tmp = { ...prev };
              tmp.questions[tmp.questions.length - 1] = question;
              tmp.logs = [...tmp.logs, ...logs];

              return { ...tmp };
            });
          }

          setLastQuestion(null);
          setLastAnswer(null);
          setIsWaitingChatResponse(false);

          break;
        default:
      }
    }
  }, [lastJsonMessage, setLastAnswer, setChat]);

  const wsSendJson = (json) => {
    sendJsonMessage(json);
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: t("knowledge_base_status_connecting"),
    [ReadyState.OPEN]: t("knowledge_base_status_open"),
    [ReadyState.CLOSING]: t("knowledge_base_status_closing"),
    [ReadyState.CLOSED]: t("knowledge_base_status_closed"),
    [ReadyState.UNINSTANTIATED]: t("knowledge_base_status_uninstantiated"),
  }[readyState];

  return (
    <>
      <>
        <Stack direction="row" justifyContent="center" alignItems="flex-start">
          {!isFullscreen && (
            <ChatLeftPanel
              connectionStatus={connectionStatus}
              chats={chats}
              handleAddChat={() => setIsAddNewChatDialogOpen(true)}
              handleChangeChat={callHandleChangeChat}
              selectedChat={uuid}
              handleDeleteChat={handleDeleteChat}
              allCategories={categories}
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
            />
          )}
          {uuid && chat !== null ? (
            <ChatMainPanel
              chat={chat}
              sendMessage={handleSendMessage}
              openSettings={() => setIsSettingsDialogOpen(true)}
              isFullscreen={isFullscreen}
              setFullscreen={setFullscreen}
            />
          ) : (
            <Stack
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              width="100vw"
              height="80vh"
              sx={{ background: "black", marginY: "0", marginX: "0" }}
            >
              <Typography variant={"h4"} sx={{ color: "white" }}>
                {t("knowledge_base_select_chat")}
              </Typography>
            </Stack>
          )}
        </Stack>
        {isDeleteChatDialogOpen && (
          <Suspense fallback="Loading...">
            <DeleteChatDialog
              open={isDeleteChatDialogOpen}
              handleClose={() => setIsDeleteChatDialogOpen(false)}
              handleDelete={deleteChat}
            />
          </Suspense>
        )}
        {isAddNewChatDialogOpen && (
          <Suspense fallback="Loading...">
            <AddNewChatDialog
              open={isAddNewChatDialogOpen}
              handleClose={() => setIsAddNewChatDialogOpen(false)}
              handleAdd={addNewChat}
            />
          </Suspense>
        )}
        {isSettingsDialogOpen && (
          <Suspense fallback="Loading...">
            <SettingsDialog
              open={isSettingsDialogOpen}
              handleClose={() => setIsSettingsDialogOpen(false)}
              handleSave={saveSettings}
              settings={{
                model: model,
                temperature: temperature,
                max_tokens: maxTokens,
              }}
            />
          </Suspense>
        )}
      </>
    </>
  );
};
export default Chat;
