export type TreeViewProps = {
  config: TreeViewConfig;
  data: TreeViewData[];
  handleEditElement?: (id: number, title: string, childs?: TreeViewData[]) => void;
  handleAddElement?: (id: number) => void;
  handleDeleteElement?: (id: number, type: TreeViewDataType, parentId?: number) => void;
  handleDropElement?: (
    item: { id: number; parentId?: number; type: TreeViewDataType },
    thrownAtItem: { id: number; parentId?: number; type: TreeViewDataType },
  ) => void;
  checkboxed?: string[];
  onToggleCheckbox?: (ids: string[]) => void;
};

export enum TreeViewType {
  FLAT,
  TREE,
}

export type TreeViewConfig = {
  view: TreeViewType;
  itemIcon: React.ReactElement;
  groupClosed: React.ReactElement;
  groupOpened: React.ReactElement;
};

export enum TreeViewDataType {
  ITEM = "ITEM",
  GROUP = "GROUP",
}

export type TreeViewData = {
  id: number;
  title: string;
  type: TreeViewDataType;
  childs?: TreeViewData[];
  parentId?: number;
  clickable?: boolean;
  checkable?: boolean;
};

export const ItemTypes = {
  ITEM: "ITEM",
};
