import React, { FC, useRef } from "react";

import * as Yup from "yup";

import { Grid, Paper, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

import CustomizedTextField from "components/Edit/CustomizedTextField";
import EditViewLanguage from "components/EditViewLanguage";
import { useTranslation } from "react-i18next";

const endpoint = "/sms-setting/";
const title = "settings_sms_settings_label";
const moduleUrl = "/settings/sms/";

const SmsEdit: FC = () => {
  const validationSchema = Yup.object({
    sender: Yup.string().nullable(),
    gatewayUrl: Yup.string().nullable(),
    userName: Yup.string().nullable(),
    password: Yup.string().nullable(),
    senderId: Yup.string().nullable(),
    key: Yup.string().nullable(),
  });

  const defaultValue = {
    sender: "",
    gatewayUrl: "",
    userName: "",
    password: "",
    senderId: "",
    key: "",
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        noDeleteButton={true}
        noBackButton={true}
        itemWithoutId={true}
        defaultValue={defaultValue}
      >
        <FormData />
      </EditViewLanguage>
    </>
  );
};

const FormData = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h4" component={"h4"}>
              {t("settings")}
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={t("sms_sender")} name={"sender"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={t("sms_gateway_url")} name={"gatewayUrl"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={t("sms_user_name")} name={"userName"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={t("sms_password")} name={"password"} type={"password"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={t("sms_sender_name")} name={"senderId"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={t("sms_client_key")} name={"key"} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default SmsEdit;
