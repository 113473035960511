import React from "react";

import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const AcceptNodeFragment = ({
  data,
  fullView,
  setFullView,
  setAcceptCode,
  setIsOpenConfirmDialog,
  isAcceptNodeAvailable,
}) => {
  return (
    <>
      {fullView && (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setFullView(false);
            }}
          >
            <ExpandLessIcon />
          </IconButton>
          {data?.accepts.map((accept) => (
            <Button
              disabled={!isAcceptNodeAvailable}
              key={accept.id}
              onClick={(e) => {
                e.stopPropagation();
                setAcceptCode(accept.code);
                setIsOpenConfirmDialog(true);
              }}
            >
              {accept.description}
            </Button>
          ))}
        </Stack>
      )}
    </>
  );
};

export default AcceptNodeFragment;
