import axios from "axios";

import getBackendHost from "utils/backendUrl";

const backend = axios.create({
  withCredentials: true,
  baseURL: getBackendHost(),
  validateStatus: function () {
    return true;
  },
});

export default backend;
