import React, { FC } from "react";

import TableCell from "@mui/material/TableCell";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIcon from "@mui/icons-material/PhoneEnabled";
import { alpha } from "@mui/material/styles";

import * as color from "assets/styles/jss/common/colors";
type CellMarketingConsentsProps = {
  data: any;
  row?: any;
  handleClick?: (e: any, row: any) => void;
};
const CellMarketingConsents: FC<CellMarketingConsentsProps> = ({ data, row, handleClick }) => {
  return (
    <TableCell onClick={(e) => (handleClick ? handleClick(e, row) : null)}>
      {data?.mailConsent && (
        <MailIcon fontSize={"small"} sx={{ marginRight: "10px", color: alpha(color.black, 0.7) }} />
      )}
      {data?.phoneConsent && <PhoneIcon fontSize={"small"} sx={{ color: alpha(color.black, 0.7) }} />}
    </TableCell>
  );
};

export default CellMarketingConsents;
