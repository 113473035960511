import React, { ForwardRefRenderFunction, forwardRef, useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import { Button, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Stack from "@mui/material/Stack";

import CategoryEditListPanel from "./CategoryEditListPanel";

const allPage = [
  {
    id: 1,
    title: "Jak przygotować się do badań?",
  },
  {
    id: 2,
    title: "Test na koronawirusa Lubin - gdzie wykonać badanie?",
  },
  {
    id: 3,
    title: "Insulina - normy, badanie, insulina w ciąży",
  },
  {
    id: 4,
    title: "Do których badań należy podejść na czczo?",
  },
  {
    id: 5,
    title: "Rak jelita grubego - badania",
  },
  {
    id: 6,
    title: "Badania genetyczne",
  },
  {
    id: 7,
    title: "Badania x",
  },
  {
    id: 8,
    title: "Badania y",
  },
];

type PageItem = {
  id: number;
  title: string;
};

const CategoryEditListDialog: ForwardRefRenderFunction<HTMLInputElement, any> = ({}) => {
  const [rightCheckedId, setRightCheckedId] = useState(-1);
  const [leftCheckedId, setLeftCheckedId] = useState(-1);
  const [leftArray, setLeftArray] = useState<PageItem[]>(allPage);
  const [leftFilteredArray, setLeftFilteredArray] = useState(allPage);
  const [rightArray, setRightArray] = useState<PageItem[]>([]);
  const [rightFilteredArray, setRightFilteredArray] = useState<PageItem[]>([]);
  const [rightFilter, setRightFilter] = useState("");
  const [leftFilter, setLeftFilter] = useState("");

  // const open = useSelector(selectMaterialDialogOpen);

  useEffect(() => {
    filtrationLeftArray();
  }, [leftFilter, leftArray]);

  useEffect(() => {
    filtrationRightArray();
  }, [rightFilter, rightArray]);

  const handleAllRight = () => {
    setRightArray(rightArray.concat(leftFilteredArray));

    let newArray = [...leftArray];
    for (const element of leftFilteredArray) {
      newArray = newArray.filter((item) => item.id !== element.id);
    }

    setLeftArray(newArray);
  };

  const handleAllLeft = () => {
    setLeftArray(leftArray.concat(rightFilteredArray));

    let newArray = [...rightArray];
    for (const element of rightFilteredArray) {
      newArray = newArray.filter((item) => item.id !== element.id);
    }

    setRightArray(newArray);
  };

  const handleCheckedRight = () => {
    if (leftFilteredArray.some((item) => item.id === leftCheckedId)) {
      setRightArray(rightArray.concat(leftArray.filter((item) => item.id === leftCheckedId)));
      setLeftArray(leftArray.filter((item) => item.id !== leftCheckedId));
      setRightCheckedId(leftCheckedId);
      setLeftCheckedId(-1);
    }
  };

  const handleCheckedLeft = () => {
    if (rightFilteredArray.some((item) => item.id === rightCheckedId)) {
      setLeftArray(leftArray.concat(rightArray.filter((item) => item.id === rightCheckedId)));
      setRightArray(rightArray.filter((item) => item.id !== rightCheckedId));
      setLeftCheckedId(rightCheckedId);
      setRightCheckedId(-1);
    }
  };

  const filtrationLeftArray = () => {
    const newArray = leftArray.filter((item) => item.title.toLowerCase().includes(leftFilter.toLowerCase()));
    setLeftFilteredArray(newArray);
  };

  const filtrationRightArray = () => {
    const newArray = rightArray.filter((item) => item.title.toLowerCase().includes(rightFilter.toLowerCase()));
    setRightFilteredArray(newArray);
  };

  return (
    <>
      <Dialog
        open={false}
        keepMounted
        disablePortal
        // onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant={"h4"} sx={{ textAlign: "center" }}>
              Edytuj listę
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <CategoryEditListPanel
              checkedId={leftCheckedId}
              setCheckedId={setLeftCheckedId}
              arrayItems={leftFilteredArray}
              setFilter={setLeftFilter}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="column" justifyContent="flex-end" alignItems="center" spacing={2} sx={{ mt: "55px" }}>
              <IconButton onClick={handleAllRight}>
                <KeyboardDoubleArrowRightIcon />
              </IconButton>
              <IconButton onClick={handleCheckedRight}>
                <KeyboardArrowRightIcon />
              </IconButton>
              <IconButton onClick={handleAllLeft}>
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
              <IconButton onClick={handleCheckedLeft}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <CategoryEditListPanel
              checkedId={rightCheckedId}
              setCheckedId={setRightCheckedId}
              arrayItems={rightFilteredArray}
              setFilter={setRightFilter}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                console.info("run");
                // dispatch(materialDialogOpen(false));
              }}
            >
              Zamknij
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* <Button variant="contained" color="primary" onClick={handleSubmit}>
              Zapisz
            </Button> */}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
export default forwardRef(CategoryEditListDialog);
