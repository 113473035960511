import React, { FC, Fragment, memo, useEffect, useRef } from "react";
import ListItem from "@mui/material/ListItem";
import { mainPanelAnswer, mainPanelQuestion, white } from "assets/colors";
import { ListItemIcon, ListItemText } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ComputerIcon from "@mui/icons-material/Computer";
import List from "@mui/material/List";
import { ChatType, QuestionType } from "modules/KnowledgeBase/types/types";

type ChatHistoryProps = {
  chat: ChatType;
};

type ConversationHistoryProps = {
  conversation: QuestionType[];
};

type ConversationItemProps = {
  content: string;
  index: number;
  item: QuestionType;
};

const ConversationItem: FC<ConversationItemProps> = memo(function ConversationItem({ content, index, item }) {
  return (
    <Fragment key={item.id}>
      {!(index === 0 && item.message === "") && (
        <ListItem sx={{ background: mainPanelQuestion, borderRadius: "8px" }}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" sx={{ color: white }} />
          </ListItemIcon>
          <ListItemText
            primary={item.message}
            primaryTypographyProps={{
              color: "white",
            }}
          />
        </ListItem>
      )}
      <ListItem sx={{ background: mainPanelAnswer, borderRadius: "8px" }}>
        <ListItemIcon>
          <ComputerIcon fontSize="small" sx={{ color: white }} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            color: "white",
          }}
          primary={content}
          sx={{ whiteSpace: "pre-line" }}
        />
      </ListItem>
    </Fragment>
  );
});

const ConversationHistory: FC<ConversationHistoryProps> = ({ conversation }) => {
  return (
    <>
      {conversation.map((item, index) => {
        const content = item.answers?.messages.length > 0 ? item.answers?.messages[0].message_content : "";
        return <ConversationItem key={item.id} content={content} index={index} item={item} />;
      })}
    </>
  );
};

const ChatHistory: FC<ChatHistoryProps> = ({ chat }) => {
  const endList = useRef(null);

  useEffect(() => {
    if (endList.current) {
      const current: any = endList.current;

      current.scrollIntoView({
        behavior: "auto",
      });
    }
  }, [chat]);

  return (
    <List
      dense
      sx={{
        maxHeight: "100%",
        paddingY: 1,
        overflow: "auto",
        color: white,
      }}
    >
      <ConversationHistory conversation={chat.questions} />
      <ListItem ref={endList} />
    </List>
  );
};

export default ChatHistory;
