import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import { Button } from "@mui/material";

import styles from "./styles";
import createStyles from "@mui/styles/createStyles";
const useStyles = makeStyles(createStyles(styles));

const HeaderButton = ({ itemData, action }) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Button
      disabled={itemData.disabled}
      size="small"
      color="primary"
      variant="contained"
      onClick={action}
      className={classes.actionButton}
      sx={{ mx: 5 }}
    >
      {t(itemData.name)}
    </Button>
  );
};

export default HeaderButton;
