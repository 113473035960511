import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Paper } from "@mui/material";

import HeaderBuilder from "layout/Header/HeaderBuilder";
import Layout from "layout/Layout";

import DataTable from "components/Table/DataTable";

const toolbarItems = [
  {
    name: "toolbar_button_add",
    tooltip: "toolbar_button_add_tooltip",
    icon: "",
    action: "add",
    disabled: false,
    sortOrder: 0,
    type: "BUTTON",
  },
  {
    name: "toolbar_icon_copy",
    tooltip: "toolbar_icon_duplicate_tooltip",
    icon: "ContentCopy",
    action: "copy",
    disabled: false,
    sortOrder: 1,
    type: "ICON",
  },
  {
    name: "toolbar_icon_del",
    tooltip: "toolbar_icon_del_tooltip",
    icon: "Delete",
    action: "delete",
    disabled: false,
    sortOrder: 2,
    type: "ICON",
  },
  {
    name: "toolbar_search_field",
    tooltip: "toolbar_search_field_tooltip",
    icon: "Search",
    action: "search",
    disabled: false,
    sortOrder: 4,
    type: "FIELDS_SEARCH",
  },
];

const CategoriesList: FC = () => {
  const { t } = useTranslation("common");
  const additionalParameters = { languageSupport: true };

  return (
    <Layout
      header={t("cms_categories_label")}
      top={<HeaderBuilder headerData={toolbarItems} moduleUrl={"/cms/categories"} />}
    >
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <DataTable
          modulePath={"/cms/categories"}
          endpoint={"/article-category/"}
          additionalParameters={additionalParameters}
        />
      </Paper>
    </Layout>
  );
};
export default CategoriesList;
