import { Typography } from "@mui/material";
import Layout from "layout/Layout";
import React from "react";

const CoursesPage = () => {
  return (
    <Layout hiddenMenu>
      <Typography variant="h1">Courses</Typography>
    </Layout>
  );
};
export default CoursesPage;
