import { Box } from "@mui/system";
import React from "react";
import Image from "mui-image";
import { Typography } from "@mui/material";

const MainNews = () => {
  return (
    <Box display="flex">
      <Box>
        <Image shift="left" width="100%" src="/news/image2.avif" style={{ borderRadius: 16 }} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        ml={4}
        mr={4}
        alignItems={"flex-start"}
        style={{ display: "block", position: "relative" }}
      >
        <Typography variant="h6">TECHNOLOGY</Typography>
        <Typography variant="h6" sx={{ color: "#c0c0c0" }} pb={6}>
          Jhony Bravo • October 21,2033
        </Typography>
        <Typography variant="h4" pb={1} sx={{ fontSize: "26px" }}>
          Architectural Engineering Wonders of the modern era for your Inspiration
        </Typography>
        <Typography pb={1}>
          Reinvention often comes in spurts, after a long period of silence. Just as modern architecture recently
          enjoyed a comeback, brand architecture, a field with well-established principles for decades, is back in the
          limelight.
        </Typography>
        <Typography pb={1}>
          Reinvention often comes in spurts, after a long period of silence. Just as modern architecture recently
          enjoyed a comeback, brand architecture, a field with well-established principles for decades, is back in the
          limelight.
        </Typography>
        <Typography pb={1}>
          Reinvention often comes in spurts, after a long period of silence. Just as modern architecture recently
          enjoyed a comeback, brand architecture, a field with well-established principles for decades, is back in the
          limelight.
        </Typography>
        <Typography pb={2}>
          Reinvention often comes in spurts, after a long period of silence. Just as modern architecture recently
          enjoyed a comeback, brand architecture, a field with well-established principles for decades, is back in the
          limelight.
        </Typography>
      </Box>
    </Box>
  );
};
export default MainNews;
