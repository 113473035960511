import React, { FC, memo, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

const nullValue = [{ id: "", displayName: " - - - " }];

type CustomizedSelectProps = {
  label: string;
  name: string;
  items: {
    id: any;
    displayName: string;
  }[];
  disabled?: boolean;
  defaultValue?: string;
  noNullValue?: boolean;
};

const CustomizedSelect: FC<CustomizedSelectProps> = ({
  label,
  name,
  items,
  disabled = false,
  defaultValue,
  noNullValue = false,
}) => {
  const { control } = useFormContext();

  const [values, setValues] = useState(nullValue);

  useEffect(() => {
    if (!noNullValue) {
      setValues([...nullValue, ...items]);
    } else {
      setValues([...items]);
    }
  }, [items]);

  return (
    <Grid item xs={12}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <FormControl fullWidth size={"small"}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue ? defaultValue : null}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: {}, formState }) => (
            <>
              <Select
                onBlur={onBlur} // notify when input is touched
                onChange={onChange} // send value to hook form
                value={value === undefined || value === null ? "" : value}
                inputRef={ref}
                error={!!formState?.errors[name]}
                disabled={disabled}
                displayEmpty={false}
              >
                {values.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.displayName}
                  </MenuItem>
                ))}
              </Select>
              {formState?.errors[name] && (
                <FormHelperText error={true}>
                  <>{formState?.errors[name]?.message}</>
                </FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
    </Grid>
  );
};

export default memo(CustomizedSelect);
