import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Paper } from "@mui/material";

import HeaderBuilder from "layout/Header/HeaderBuilder";
import Layout from "layout/Layout";

import DataTable from "components/Table/DataTable";

const toolbarItems = [
  {
    name: "toolbar_search_field",
    tooltip: "toolbar_search_field_tooltip",
    icon: "Search",
    action: "search",
    disabled: false,
    sortOrder: 4,
    type: "FIELDS_SEARCH",
    searchFieldName: "login:like",
  },
];

const LogList: FC = () => {
  const { t } = useTranslation("common");

  return (
    <Layout
      header={t("settings_log_label")}
      top={<HeaderBuilder headerData={toolbarItems} moduleUrl={"/settings/log"} />}
    >
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <DataTable isClickable={false} modulePath={"/settings/log"} endpoint={"/audit/"} />
      </Paper>
    </Layout>
  );
};
export default LogList;
