import React from "react";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";

import styles from "./styles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles(createStyles(styles));

const HeaderIcon = ({ itemData, action, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <>
      {(itemData.disabled || disabled ? true : false) ? (
        <IconButton disabled={true} color="primary" className={classes.actionIcon} size="large">
          {itemData.icon === "ContentCopy" && <ContentCopyIcon fontSize={"small"} />}
          {itemData.icon === "Delete" && <DeleteIcon fontSize={"medium"} />}
        </IconButton>
      ) : (
        <Tooltip title={t(itemData.tooltip)} placement="bottom" arrow={true}>
          <IconButton color="primary" onClick={action} className={classes.actionIcon} size="large">
            {itemData.icon === "ContentCopy" && <ContentCopyIcon fontSize={"small"} />}
            {itemData.icon === "Delete" && <DeleteIcon fontSize={"medium"} />}
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default HeaderIcon;
