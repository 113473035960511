import React, { memo } from "react";

import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { InputLabel } from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import format from "date-fns/format";

function CustomizedDateTimePickerMui({ label, name = "x", disabled = false, defaultEmpty = false }) {
  const { control } = useFormContext();

  const handleChange = (value, onChange) => {
    console.info(value);
    if (value) {
      onChange(value);
    } else {
      onChange("");
    }
  };

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultEmpty ? new Date() : format(new Date(), "yyyy-MM-ddTHH:mm")}
        render={({ field: { onChange, value, name }, fieldState: {}, formState }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <DateTimePicker
              inputFormat={"yyyy-MM-dd HH:mm"}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  size="small"
                  helperText={formState?.errors[name] ? <>formState?.errors[name].message</> : ""}
                  error={!!formState?.errors[name]}
                />
              )}
              readOnly={disabled}
              value={value}
              onChange={(v) => handleChange(v, onChange)}
            />
          </LocalizationProvider>
        )}
      />
    </>
  );
}

export default memo(CustomizedDateTimePickerMui);
