import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";

export const fetchIsLogged = createAsyncThunk("user/isLogged", async () => {
  const url = `/user/isLogged`;
  const res = await backend.get(url);
  return res.status;
});

export const fetchCurrentUser = createAsyncThunk("user/fetch", async () => {
  const url = `/user/profile`;
  const res = await backend.get(url);
  return res.data;
});

export const logoutUser = createAsyncThunk("user/logout", async () => {
  const url = `/logout`;
  const res = await backend.get(url);
  return res.status;
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    current: {},
    isLogged: true,
    isLoading: false,
  },
  reducers: {
    setUserLogged: (state, action) => {
      state.isLogged = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIsLogged.fulfilled, (state, action) => {
      const payload = action.payload;
      state.isLogged = payload === 200;
    }),
      builder.addCase(fetchCurrentUser.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchCurrentUser.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.current = action.payload;
      }),
      builder.addCase(logoutUser.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(logoutUser.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(logoutUser.fulfilled, (state) => {
        state.isLoading = false;
        state.isLogged = false;
        state.current = {};
      });
  },
});

export const selectUser = (state) => state.user.current;
export const selectIsLoadingUser = (state) => state.user.isLoading;
export const selectIsLogged = (state) => state.user.isLogged;

export const { setUserLogged } = userSlice.actions;

export default userSlice.reducer;
