import * as color from "assets/styles/jss/common/colors";

const styles = {
  profileInfo: {
    // height: 100,
    // padding: 10,
  },
  img: {
    height: 35,
    width: 35,
    borderRadius: 50,
    marginRight: 15,
  },
  user: {
    display: "flex",
    alignItems: "center",
    color: color.white,
  },
};

export default styles;
