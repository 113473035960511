import { Paper } from "@mui/material";
import { TreeViewConfig, TreeViewData, TreeViewDataType, TreeViewType } from "components/TreeView";
import TreeView from "components/TreeView/TreeView";

import Layout from "layout/Layout";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PersonIcon from "@mui/icons-material/Person";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import Toolbar from "@mui/material/Toolbar";
import HeaderButton from "layout/Header/HeaderButton";
import Typography from "@mui/material/Typography";
import backend from "../../../api/backend";
import { isSuccess } from "../../../utils/http";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { ShortIdNameType } from "types/types";

const EditGroupDialog = lazy(() => import("modules/settings/SettingsAccess/EditGroupDialog"));
const ConfirmationDialog = lazy(() => import("components/ConfirmationDialog"));

const endpoint = "/groups/";
const endpointEditGroup = endpoint + "save";
const endpointRemoveGroup = endpoint + "remove-item";
const endpointMoveGroup = endpoint + "move-item";
const endpointGetAllUsers = endpoint + "get-users";

const config: TreeViewConfig = {
  view: TreeViewType.FLAT,
  itemIcon: <PersonIcon />,
  groupClosed: <FolderIcon />,
  groupOpened: <FolderOpenIcon />,
};

const GroupView = () => {
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<ShortIdNameType[] | null>(null);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false);

  const [editedItemParentId, setEditedItemParentId] = useState<number | null>(null);
  const [itemToRemove, setItemToRemove] = useState<null | { id: number; type: TreeViewDataType; parentId?: number }>(
    null,
  );
  const [groupToEdit, setGroupToEdit] = useState<{
    id: number;
    title: string;
    childs?: TreeViewData[];
  } | null>(null);

  useEffect(() => {
    getGroups();
    getUsers();
  }, []);

  const getGroups = () => {
    setIsLoading(true);

    backend
      .get(endpoint)
      .then((res) => {
        if (isSuccess(res) && res.data?.groups) {
          setGroups(res.data.groups);
        }
      })
      .catch((reason) => {
        console.error(reason);
      })
      .finally(() => setIsLoading(false));
  };

  const getUsers = () => {
    backend
      .get(endpointGetAllUsers)
      .then((res) => {
        if (isSuccess(res) && res.data) {
          setAllUsers(res.data);
        }
      })
      .catch((reason) => {
        setAllUsers([]);
        console.error(reason);
      });
  };

  const handleCloseDialog = () => {
    setGroupToEdit(null);
    setEditedItemParentId(null);
    setItemToRemove(null);

    setIsEditDialogOpen(false);
    setIsRemoveDialogOpen(false);
  };

  const handleConfirmEditGroup = (title: string, users: ShortIdNameType[]) => {
    const editedGroup = { id: -1, title: title, parentId: editedItemParentId, users: users };
    if (groupToEdit) {
      editedGroup.id = groupToEdit.id;
    }

    backend
      .post(endpointEditGroup, editedGroup)
      .then((res: AxiosResponse) => {
        if (isSuccess(res)) {
          enqueueSnackbar(t("common_status_successfully"), {
            variant: "success",
          });
          if (res.data?.groups) {
            setGroups(res.data.groups);
          }
        } else {
          enqueueSnackbar(t("common_status_error") + res.status + "/" + res.data.message, {
            variant: "error",
          });
        }
      })
      .catch((reason) => {
        console.error(reason);
      });

    handleCloseDialog();
  };

  const handleConfirmRemoveItem = () => {
    backend
      .post(endpointRemoveGroup, itemToRemove)
      .then((res: AxiosResponse) => {
        if (isSuccess(res)) {
          enqueueSnackbar(t("common_status_successfully"), {
            variant: "success",
          });
          if (res.data?.groups) {
            setGroups(res.data.groups);
          }
        } else {
          enqueueSnackbar(t("common_status_error") + res.status + "/" + res.data.message, {
            variant: "error",
          });
        }
      })
      .catch((reason) => {
        console.error(reason);
      });

    handleCloseDialog();
  };

  const handleAddElement = (id: number) => {
    setIsEditDialogOpen(true);
    setEditedItemParentId(id);
  };

  const handleDeleteElement = (id: number, type: TreeViewDataType, parentId?: number) => {
    setIsRemoveDialogOpen(true);
    setItemToRemove({ id: id, type: type, parentId: parentId });
  };

  const handleEditElement = (id: number, title: string, childs?: TreeViewData[]) => {
    setGroupToEdit({ id: id, title: title, childs: childs });
    setIsEditDialogOpen(true);
  };

  const handleDropElement = (
    item: { id: number; parentId?: number; type: TreeViewDataType },
    thrownAtItem: { id: number; parentId?: number; type: TreeViewDataType },
  ) => {
    const data = { item: item, thrownAtItem: thrownAtItem };

    if (
      item.type === TreeViewDataType.GROUP &&
      thrownAtItem.type === TreeViewDataType.GROUP &&
      item.id === thrownAtItem.id
    ) {
      return;
    }

    backend
      .post(endpointMoveGroup, data)
      .then((res: AxiosResponse) => {
        if (isSuccess(res)) {
          enqueueSnackbar(t("common_status_successfully"), {
            variant: "success",
          });
          if (res.data?.groups) {
            setGroups(res.data.groups);
          }
        } else {
          enqueueSnackbar(t("common_status_error") + res.status + "/" + res.data.message, {
            variant: "error",
          });
        }
      })
      .catch((reason) => {
        console.error(reason);
      });
  };

  return (
    <Layout
      header={t("settings_groups_label")}
      top={
        <Toolbar>
          <HeaderButton
            itemData={{ name: t("settings_groups_add_main") }}
            action={() => {
              setIsEditDialogOpen(true);
            }}
          />
        </Toolbar>
      }
    >
      <Paper sx={{ p: 10 }} elevation={8}>
        {isLoading ? (
          <CircularProgress />
        ) : !groups || groups.length === 0 ? (
          <Typography>{t("settings_groups_empty_list")}</Typography>
        ) : (
          <TreeView
            config={config}
            data={groups}
            handleAddElement={handleAddElement}
            handleEditElement={handleEditElement}
            handleDeleteElement={handleDeleteElement}
            handleDropElement={handleDropElement}
          />
        )}
        {isEditDialogOpen && allUsers && (
          <Suspense fallback={<>Loading...</>}>
            <EditGroupDialog
              allUsers={allUsers}
              open={isEditDialogOpen}
              handleClose={handleCloseDialog}
              handleConfirm={handleConfirmEditGroup}
              itemToEdit={groupToEdit}
              title={
                groupToEdit ? t("settings_groups_dialog_title_edit_group") : t("settings_groups_dialog_title_add_group")
              }
            />
          </Suspense>
        )}
        {isRemoveDialogOpen && (
          <Suspense fallback={<>Loading...</>}>
            <ConfirmationDialog
              close={handleCloseDialog}
              confirm={handleConfirmRemoveItem}
              customTitle={
                itemToRemove?.type === TreeViewDataType.GROUP
                  ? t("settings_groups_dialog_title_remove_group")
                  : t("settings_groups_dialog_title_remove_user")
              }
              open={isRemoveDialogOpen}
            />
          </Suspense>
        )}
      </Paper>
    </Layout>
  );
};
export default GroupView;
