import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import { AppBar, Box, Hidden, Theme } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";

import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";

import ProfileInfo from "layout/Menu/ProfileInfo/ProfileInfo";

import Header from "./Header";
import Sidebar from "./Menu";

import { ReactComponent as Logo } from "assets/img/logo.svg";

import styles from "./styles";
import OrganizationInfo from "./Menu/OrganizationInfo/OrganizationInfo";
import { useSelector } from "react-redux";
import { selectMenuItems, selectModuleItems } from "redux/slices/menu";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles<Theme>(createStyles(styles));

type LayoutProps = {
  header?: React.ReactNode;
  children?: React.ReactNode;
  actionButton?: React.ReactNode;
  top?: React.ReactNode;
  hiddenMenu?: boolean;
};

const Layout: FC<LayoutProps> = ({ header, children, actionButton, top, hiddenMenu = false }) => {
  const classes = useStyles();

  const modulesItems = useSelector(selectModuleItems);
  const menuItems = useSelector(selectMenuItems);
  const history = useHistory();

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleClose = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div className={classes.layoutContainer}>
      <AppBar>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Logo style={{ marginRight: 1, cursor: "pointer" }} onClick={() => history.push("/dashboard")} />
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {modulesItems.map((page) => (
                  <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {modulesItems.map((page) => (
                <Button
                  key={page.id}
                  onClick={() => history.push(page.path)}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
            {/* <Box sx={{ margin: "5px 20px" }}>
              <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Box> */}
            <OrganizationInfo />
            <ProfileInfo />
          </Toolbar>
        </Container>
      </AppBar>

      {!hiddenMenu && (
        <>
          <Hidden mdDown>
            <div>
              <Sidebar menuItems={menuItems} />
            </div>
          </Hidden>

          <Hidden mdUp>
            {mobileOpen && (
              <Box id="mobile-menu" className={classes.mobileMenu} sx={{ zIndex: "modal" }}>
                <Sidebar menuItems={menuItems} />
              </Box>
            )}
          </Hidden>
        </>
      )}

      <div style={{ width: "100%" }}>
        <Header actionButton={actionButton} mobileToggle={handleClose} top={top} mobileOpened={mobileOpen}>
          {header}
        </Header>
        <div className={classes.main}>{children}</div>
      </div>
    </div>
  );
};
export default Layout;
