import React, { FC } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TreeViewProps } from "./types";
import TreeViewComponent from "./TreeViewComponent";

const TreeView: FC<TreeViewProps> = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <TreeViewComponent {...props} />
    </DndProvider>
  );
};
export default TreeView;
