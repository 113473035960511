import React, { ForwardRefRenderFunction, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import * as Yup from "yup";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from "@mui/material";

import backend from "api/backend";

import CustomizedDropZone from "components/Edit/CustomizedDropZone";
import EditViewNoLanguage from "components/EditViewNoLanguage";
import CustomizedTextField from "components/Edit/CustomizedTextField";

import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import getBackendHost from "utils/backendUrl";
import DetailsTable from "modules/settings/Documents/DetailsTable";
import { fetchCrudItem } from "redux/slices/crud";
import { useDispatch } from "react-redux";
import TreeView, { TreeViewConfig, TreeViewType } from "components/TreeView";
import PersonIcon from "@mui/icons-material/Person";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";

const endpoint = "/documents/";
const title = "document_own_edit_label";
const moduleUrl = "/documents/own/";

const config: TreeViewConfig = {
  view: TreeViewType.FLAT,
  itemIcon: <PersonIcon />,
  groupClosed: <FolderIcon />,
  groupOpened: <FolderOpenIcon />,
};

const OwnDocumentEdit: ForwardRefRenderFunction<HTMLTextAreaElement, any> = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    id: Yup.string().nullable(),
    name: Yup.string().trim().required(t("form-validation-required")),
    description: Yup.string(),
    file: Yup.mixed().when("id", {
      is: undefined,
      then: Yup.mixed().required(t("form-validation-select-file")),
    }),
  });

  const refForm = useRef(null);
  const childRef = useRef<HTMLElement & { uploadFile: () => void }>(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        customSaveFunc={() => childRef?.current?.uploadFile()}
      >
        <FormDataCustom id={id} refForm={refForm} ref={childRef} />
      </EditViewNoLanguage>
    </>
  );
};

// eslint-disable-next-line react/display-name
const FormDataCustom = forwardRef<any, { id: string; refForm: any; ref: any }>((props, ref) => {
  const { id, refForm } = props;
  const idIsNumber = !isNaN(id as any);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { watch, setValue, getValues, formState } = useFormContext();
  const { errors } = formState;

  const { t } = useTranslation("common");
  const watchName = watch("name");
  const watchVersions = watch("versions");
  const watchGroups = watch("groups");
  const watchSharingIdsString = watch("sharingIdsString");

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploadFileInProgress, setIsUploadFileInProgress] = useState(false);

  useEffect(() => {
    if (selectedFile !== null) {
      if (!idIsNumber) {
        setValue("name", selectedFile["name"], { shouldTouch: true, shouldDirty: true, shouldValidate: true });
      }
      setValue("file", selectedFile, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
    }
  }, [selectedFile]);

  useImperativeHandle(ref, () => ({
    async uploadFile() {
      if (isUploadFileInProgress) {
        enqueueSnackbar(t("document_edit_wait_for_file_uploaded"), { variant: "warning" });
      } else if (selectedFile) {
        setIsUploadFileInProgress(true);
        const sharingIdsStr = getValues("sharingIdsString") ? getValues("sharingIdsString") : [];
        const formData = new FormData();
        formData.append("file", getValues("file"));
        formData.append("name", getValues("name"));
        formData.append("originalName", selectedFile["name"]);
        formData.append("description", getValues("description"));
        formData.append("sharingIdsString", sharingIdsStr);
        if (idIsNumber) {
          formData.append("id", id);
        }
        const headers = {
          "Content-Type": `multipart/form-data;`,
        };
        backend
          .post("/documents/upload", formData, { headers: headers })
          .then((response) => {
            if (isSuccess(response)) {
              const { data } = response;
              if (data.ok) {
                enqueueSnackbar(t("common_status_successfully"), {
                  variant: "success",
                });
                if (!idIsNumber) {
                  history.push(moduleUrl + data.newId);
                } else {
                  dispatch(fetchCrudItem({ endpoint, id }));
                }
              } else {
                enqueueSnackbar(t("document_own_edit_upload_error"), {
                  variant: "error",
                });
              }
            }
          })
          .catch((reason) => console.error(reason))
          .finally(() => setIsUploadFileInProgress(false));
      } else {
        refForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
      }
    },
  }));

  const handleDownload = async (version: number, fileExtension: string, originalName) => {
    backend
      .get(`${getBackendHost()}/documents/get-own-file/${id}/${version}`)
      .then((response) => {
        if (isSuccess(response)) {
          const blob = new Blob([response.data], {
            type: fileExtension,
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", watchName ? watchName : originalName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((reason) => console.error(reason));
  };

  const handleDelete = async (version: number) => {
    backend
      .get(`${getBackendHost()}/documents/delete-file-version/${id}/${version}`)
      .then((response) => {
        if (isSuccess(response)) {
          enqueueSnackbar(t("document_own_edit_delete_version_success"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("document_own_edit_delete_version_error"), {
            variant: "error",
          });
        }
      })
      .catch((reason) => console.error(reason))
      .finally(() => {
        dispatch(fetchCrudItem({ endpoint, id }));
      });
  };

  const onToggleCheckbox = (ids: string[]) => {
    setValue("sharingIdsString", ids);
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <CustomizedDropZone
              errorMessage={errors?.file?.message as any}
              label={idIsNumber ? t("document_own_edit_select_current_file") : t("document_own_edit_select_file")}
              selectedFile={(e) => setSelectedFile(e)}
              insideLabel={t("document_own_edit_select_file_inside_label")}
              acceptFileFormat="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text,application/pdf,application/rtf,text/plain"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomizedTextField label={t("document_own_edit_file_name")} name="name" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <CustomizedTextField label={t("document_own_edit_description")} name="description" rows={4} />
          </Grid>
        </Grid>
      </Paper>
      {idIsNumber && (
        <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
          <Accordion square defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ p: 0 }}
            >
              <Typography variant="h4" component={"h4"}>
                {t("document_own_edit_details")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, mb: 5 }}>
              <DetailsTable versions={watchVersions} handleDelete={handleDelete} handleDownload={handleDownload} />
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
      {watchGroups && (
        <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
          <Accordion square defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ p: 0 }}
            >
              <Typography variant="h4" component={"h4"}>
                {t("document_own_edit_file_sharing")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, mb: 5 }}>
              <TreeView
                config={config}
                data={watchGroups.groups}
                checkboxed={watchSharingIdsString}
                onToggleCheckbox={onToggleCheckbox}
              />
            </AccordionDetails>
          </Accordion>
        </Paper>
      )}
    </>
  );
});

export default forwardRef(OwnDocumentEdit);
