import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";

export const fetchOrganiaztionList = createAsyncThunk("organization/list", async () => {
  const res = await backend.get("/organizations/loggedUser");
  return res.data;
});

export const fetchChangeOrganization = createAsyncThunk("organization/change", async (data: { id: number }) => {
  const res = await backend.post("/organizations/change", { organization: data.id });
  return res.data;
});

const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    isLoading: false,
    organizations: [],
    hasOrganizationChanged: false,
  },
  reducers: {
    setOrganizationChangedFalse(state) {
      state.hasOrganizationChanged = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganiaztionList.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(fetchOrganiaztionList.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(fetchOrganiaztionList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.organizations = action.payload;
      }),
      builder.addCase(fetchChangeOrganization.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchChangeOrganization.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(fetchChangeOrganization.fulfilled, (state) => {
        state.isLoading = false;
        state.hasOrganizationChanged = true;
      });
  },
});

export const selectOrganizationsList = (state) => state.organization.organizations;
export const selectOrganizationsLoading = (state) => state.organization.isLoading;
export const selectHasOrganizationChanged = (state) => state.organization.hasOrganizationChanged;

export const { setOrganizationChangedFalse } = organizationSlice.actions;
export default organizationSlice.reducer;
