import { Button, InputLabel, List, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { FC, lazy, Suspense, useState } from "react";
import CustomizedListItem from "components/Edit/CustomizedListItem";
import MoreLessBoxIcon from "components/MoreLessBoxIcon";
import Stack from "@mui/material/Stack";
import { ShortIdNameType } from "types/types";
import { useFormContext } from "react-hook-form";

const TwoPanelChoicerPanelDialog = lazy(() => import("components/TwoPanelChoicer/TwoPanelChoicerPanelDialog"));

type KnowledgeDocumentsPropsType = {
  allDocuments: { id: number; name: string }[] | null;
  selectedDocuments: { id: number; name: string }[];
};

const MAX_SHORT_LIST = 5;

const KnowledgeDocuments: FC<KnowledgeDocumentsPropsType> = ({ allDocuments, selectedDocuments }) => {
  const { t } = useTranslation("common");

  const [isListExpanded, setIsListExpanded] = useState<boolean>(false);
  const [isEditDocumentListDialogOpen, setIsEditDocumentListDialogOpen] = useState<boolean>(false);

  const { setValue } = useFormContext();

  const handleSetDocuments = (documents: ShortIdNameType[]) => {
    setValue("documents", documents);
    setIsEditDocumentListDialogOpen(false);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <InputLabel>{t("document_edit_knowledge_document_list")}</InputLabel>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => setIsEditDocumentListDialogOpen(true)}
        >
          {t("common_list_edit_elements")}
        </Button>
      </Stack>
      {!(selectedDocuments?.length > 0) ? (
        <Stack textAlign="center">
          <Typography>{t("common_list_empty")}</Typography>
        </Stack>
      ) : (
        <>
          <List dense sx={{ pb: 0 }}>
            {selectedDocuments?.map(
              (item, index) =>
                (index < MAX_SHORT_LIST || isListExpanded) && (
                  <CustomizedListItem key={item.id} primaryText={item.name} secondaryText={""} />
                ),
            )}
          </List>
          <MoreLessBoxIcon
            list={selectedDocuments}
            maxShortListElement={MAX_SHORT_LIST}
            fullListOpen={isListExpanded}
            setFullListOpen={setIsListExpanded}
          />
        </>
      )}
      {isEditDocumentListDialogOpen && allDocuments && (
        <Suspense fallback={<>Loading...</>}>
          <TwoPanelChoicerPanelDialog
            open={isEditDocumentListDialogOpen}
            handleClose={() => {
              setIsEditDocumentListDialogOpen(false);
            }}
            handleConfirm={handleSetDocuments}
            title={t("document_edit_select_documents_dialog_title")}
            allItems={allDocuments}
            selectedItems={selectedDocuments}
          />
        </Suspense>
      )}
    </>
  );
};
export default KnowledgeDocuments;
