import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import * as color from "assets/styles/jss/common/colors";

type ConfirmationDialogProps = {
  open?: boolean;
  close: () => void;
  confirm: () => void;
  customTitle?: string;
};

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({ open = false, close, confirm, customTitle }) => {
  const { t } = useTranslation("common");

  const handleClose = () => {
    close();
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {customTitle ? (
        <DialogTitle textAlign="center" id="alert-dialog-slide-title">
          {t(customTitle)}
        </DialogTitle>
      ) : (
        <DialogTitle id="alert-dialog-slide-title">{t("confirmation_modal_title")}</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{t("confirmation_modal_content")}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={handleClose} variant="contained" color="primary">
          {t("confirmation_modal_deny")}
        </Button>
        <Button onClick={confirm} variant="outlined" color="primary" sx={{ color: color.red }}>
          {t("confirmation_modal_agree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
