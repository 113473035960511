import "modules/KnowledgeBase/components/Typing/typing.scss";
import React from "react";

const Typing = () => {
  return (
    <>
      <br />
      <div className="typing">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <br />
    </>
  );
};

export default Typing;
