import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import TableCell from "@mui/material/TableCell";
import { alpha } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import * as color from "assets/styles/jss/common/colors";
type CellLanguageActionsProps = {
  data: any;
  row?: any;
  languages?: any;
  handleClick?: (e: any, row: any, lang: string) => void;
};

type LanguageType = {
  code: string;
  sortOrder: number;
};

const CellLanguageActions: FC<CellLanguageActionsProps> = ({ data, row, languages, handleClick }) => {
  const { t } = useTranslation("common");

  const [langs, setLangs] = useState<LanguageType[]>([]);

  useEffect(() => {
    if (languages) {
      setLangs(
        [...languages].sort((a, b) => {
          return a - b;
        }),
      );
    }
  }, [languages]);

  return (
    <>
      {langs
        ?.sort((a, b) => {
          return a.sortOrder - b.sortOrder;
        })
        .map((language) => {
          if (
            data &&
            data?.find((lang) => {
              return language.code === lang.lang;
            })
          ) {
            return (
              <TableCell key={language.code} padding={"checkbox"}>
                <Tooltip
                  title={t("table_language_actions_edit") + " (" + language.code.toUpperCase() + ")"}
                  placement="bottom"
                  arrow={true}
                  sx={{ mr: "10px" }}
                >
                  <IconButton size={"small"} onClick={(e) => (handleClick ? handleClick(e, row, language.code) : null)}>
                    <EditIcon fontSize={"small"} sx={{ color: alpha(color.primary, 1) }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            );
          } else {
            return (
              <TableCell key={language.code} padding={"checkbox"}>
                <Tooltip
                  title={t("table_language_actions_add") + " (" + language.code.toUpperCase() + ")"}
                  placement="bottom"
                  arrow={true}
                  sx={{ mr: "10px" }}
                >
                  <IconButton size={"small"} onClick={(e) => (handleClick ? handleClick(e, row, language.code) : null)}>
                    <AddCircleOutlineIcon fontSize={"small"} sx={{ color: alpha(color.black, 0.4) }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            );
          }
        })}
    </>
  );
};

export default CellLanguageActions;
