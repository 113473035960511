import React, { FC, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { HEADER_ITEM_TYPES } from "../../enums/headerItemTypes";

import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import makeStyles from "@mui/styles/makeStyles";

import HeaderSelectLanguage from "./HeaderSelectLanguage";
import HeaderTextFieldsSearch from "./HeaderTextFieldsSearch";
import HeaderIcon from "./HeaderIcon";
import HeaderButton from "./HeaderButton";

import { changeLanguage, setCurrentLanguageCode } from "redux/slices/language";

import * as color from "assets/styles/jss/common/colors";

import styles from "./styles";
import createStyles from "@mui/styles/createStyles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(createStyles(styles));
type HeaderBuilderProps = {
  isEditViewActive?: boolean;
  headerData: any;
  actionAddDialog?: any;
  actionExportDialog?: any;
  actionAddShortcut?: any;
  orderReport?: any;
  moduleUrl?: string;
  noBackButton?: boolean;
  actionIndexKnowledge?: any;
};
const HeaderBuilder: FC<HeaderBuilderProps> = ({
  isEditViewActive = false,
  headerData,
  actionAddDialog = () => {
    console.info("run");
  },
  actionExportDialog = () => {
    console.info("run");
  },
  actionAddShortcut = () => {
    console.info("run");
  },
  orderReport = () => {
    console.info("run");
  },
  moduleUrl,
  noBackButton,
  actionIndexKnowledge,
}) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [searchFieldsItem, setSearchFieldsItem] = useState(null);
  const [languageSelectItem, setLanguageSelectItem] = useState(null);
  const [exportItem, setExportItem] = useState(null);
  const [isIconPresent, setIsIconPresent] = useState(false);

  useEffect(() => {
    if (Array.isArray(headerData)) {
      setSearchFieldsItem(headerData.find((element) => element.type === HEADER_ITEM_TYPES.FIELDS_SEARCH));
      setLanguageSelectItem(headerData.find((element) => element.type === HEADER_ITEM_TYPES.SELECT_LANGUAGE));
      setExportItem(headerData.find((element) => element.type === HEADER_ITEM_TYPES.BUTTON_EXPORT));
      setIsIconPresent(headerData.some((element) => element.type === HEADER_ITEM_TYPES.ICON));
    }
  }, [headerData]);

  const actionAdd = () => {
    changeLanguage("pl").then((r) => {
      if (r === true) {
        dispatch(setCurrentLanguageCode("pl"));
        history.push(moduleUrl + "/new");
      }
    });
  };

  const choiceActionButton = (item) => {
    switch (item.action) {
      case "add":
        return actionAdd();
      case "add_dialog":
        return actionAddDialog(item);
      case "export_dialog":
        return actionExportDialog(item);
      case "add_shortcut":
        return actionAddShortcut(item);
      case "order_report":
        return orderReport(item);
      case "index_knowledge":
        return actionIndexKnowledge();
      default:
        return console.info(item.action);
    }
  };

  return (
    <Toolbar>
      {/* to left */}
      {isEditViewActive && !noBackButton && (
        <Button
          variant={"outlined"}
          size={"small"}
          color="primary"
          startIcon={<ArrowBackIosNewIcon sx={{ color: color.primary }} />}
          onClick={() => history.push(moduleUrl ?? "")}
        >
          {t("back")}
        </Button>
      )}
      {Array.isArray(headerData) &&
        headerData.map((item, i) => (
          <Fragment key={i}>
            {item.type === HEADER_ITEM_TYPES.BUTTON && (
              <HeaderButton key={i} itemData={item} action={() => choiceActionButton(item)} />
            )}
          </Fragment>
        ))}
      {isIconPresent && <Divider className={classes.divider} orientation="vertical" flexItem />}
      {Array.isArray(headerData) &&
        headerData.map((item, i) => (
          <Fragment key={i}>
            {item.type === HEADER_ITEM_TYPES.ICON && (
              <HeaderIcon key={i} itemData={item} action={() => choiceActionButton(item)} disabled={false} />
            )}
          </Fragment>
        ))}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ display: { md: "flex" } }}>
        {/* to right */}
        {exportItem && <HeaderButton itemData={exportItem} action={() => choiceActionButton(exportItem)} />}
        {languageSelectItem && <HeaderSelectLanguage itemData={languageSelectItem} />}
        {searchFieldsItem && <HeaderTextFieldsSearch itemData={searchFieldsItem} />}
      </Box>
    </Toolbar>
  );
};

export default HeaderBuilder;
