import React from "react";
import { useTranslation } from "react-i18next";

import TableCell from "@mui/material/TableCell";
import { alpha } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import * as color from "assets/styles/jss/common/colors";

const CellActions = () => {
  const { t } = useTranslation("common");

  return (
    <TableCell>
      <Tooltip title={t("table_action_cell_edit")} placement="bottom" arrow={true} sx={{ marginRight: "10px" }}>
        <IconButton size={"small"}>
          <EditIcon fontSize={"small"} sx={{ color: alpha(color.black, 0.7) }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("table_action_cell_get")} placement="bottom" arrow={true} sx={{ marginRight: "10px" }}>
        <IconButton size={"small"}>
          <DownloadIcon fontSize={"small"} sx={{ color: alpha(color.black, 0.7) }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("table_action_cell_discard")} placement="bottom" arrow={true} sx={{ marginRight: "10px" }}>
        <IconButton size={"small"}>
          <HighlightOffIcon fontSize={"small"} sx={{ color: alpha(color.black, 0.7) }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("table_action_cell_accept")} placement="bottom" arrow={true} sx={{ marginRight: "10px" }}>
        <IconButton size={"small"}>
          <CheckCircleOutlineIcon fontSize={"small"} sx={{ color: alpha(color.black, 0.7) }} />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

export default CellActions;
