import React from "react";
import { Link } from "react-router-dom";

import TableCell from "@mui/material/TableCell";
import { ListItem, ListItemText } from "@mui/material";

const CellLanguageName = ({ data }) => {
  const changeLink = <Link to="">zmień na domyślny</Link>;

  return (
    <TableCell>
      <ListItem>
        {data?.default && <ListItemText primary={data?.name} secondary={"Domyślny"} />}
        {!data?.default && <ListItemText primary={data?.name} secondary={changeLink} />}
      </ListItem>
    </TableCell>
  );
};

export default CellLanguageName;
