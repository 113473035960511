import React, { FC, memo, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

import JoditEditor from "jodit-react";

import { InputLabel } from "@mui/material";

import MediaDialog from "modules/settings/CMSResources/MediaDialog";

import * as color from "assets/styles/jss/common/colors";

const MEDIA_FOLDER = "/admin/cmsstatic";

type WysiwygProps = {
  name: string;
  label?: string;
  setInitEditor?: (instance) => void;
};

const Wysiwyg: FC<WysiwygProps> = ({
  label,
  name,
  setInitEditor = () => {
    console.info("run");
  },
}) => {
  const { control } = useFormContext();
  const [editor, setEditor] = useState<any | null>(null);

  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageAlt, setImageAlt] = useState<string | null>(null);

  const [openDialogSelectImage, setOpenDialogSelectImage] = useState(false);

  useEffect(() => {
    if (imageSrc !== null) {
      let img = '<img src="' + MEDIA_FOLDER + imageSrc + '"';
      if (imageAlt) {
        img += ' alt="' + imageAlt + '"';
      }
      img += ">";

      editor?.s?.insertHTML(img);

      setImageSrc(null);
      setImageAlt(null);
    }
  }, [imageSrc]);

  const openDialog = (edit) => {
    setEditor(edit);
    setOpenDialogSelectImage(true);
  };

  const imageButton = {
    name: "select image",
    icon: "image",
    tooltip: "select image",
    exec: function (edit) {
      openDialog(edit);
    },
  };

  //source - change mode
  const defaultConfig = {
    spellcheck: false,
    language: "pl",
    enter: "div",
    defaultMode: 1,
    toolbarAdaptive: false,
    readonly: false,
    minHeight: 256,
    height: 256,
    // buttons: ['undo', 'redo', '|', 'paragraph', '|', 'bold', 'italic', '|', 'left', 'center', 'right', 'justify', '|', 'indent', 'outdent', '|'],
    buttons: [
      "source",
      "|",
      "undo",
      "redo",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "eraser",
      "|",
      "left",
      "center",
      "right",
      "justify",
      "|",
      "indent",
      "outdent",
      "|",
      "ul",
      "ol",
      "|",
      imageButton,
      "link",
      "table",
    ],
    style: {
      background: color.white,
      color: color.black,
    },
    tabIndex: 1,
    events: {
      afterInit: (instance) => {
        setInitEditor(instance);
      },
    },
  };

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: {} }) => (
          <JoditEditor
            value={value}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            config={defaultConfig}
            onBlur={(newContent) => {
              onChange(newContent);
            }} // preferred to use only this option to update the content for performance reasons
          />
        )}
      />
      {openDialogSelectImage && (
        <MediaDialog
          open={openDialogSelectImage}
          close={() => setOpenDialogSelectImage(false)}
          setImage={setImageSrc}
          setImageAlt={setImageAlt}
        />
      )}
    </>
  );
};

export default memo(Wysiwyg);
