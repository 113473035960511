import React, { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

import { Button, Grid } from "@mui/material";

import FaqItem from "components/Edit/Faq/FaqItem";
import ConfirmationDialog from "components/ConfirmationDialog";

const name = "qa";

const GroupFaqItem = () => {
  const { control } = useFormContext<{
    qa: { id: number; question: string; answer: string }[];
  }>();
  const [nextId, setNextId] = useState(-1);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{ id: number } | null>(null);
  const { fields, append, remove } = useFieldArray({
    name: name,
    control, // control props comes from useForm (optional: if you are using FormContext)
    keyName: "idUseField",
  });

  const handleAddGroup = () => {
    const newItem = { id: nextId, question: "", answer: "" };
    append(newItem);

    setNextId((prev) => {
      return prev - 1;
    });
  };

  const handleDeleteGroup = () => {
    const index = fields.findIndex((object) => {
      return object.id === itemToDelete?.id;
    });

    remove(index);

    setItemToDelete(null);
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <Grid item xs={12}>
        {fields.map((item, index) => (
          <FaqItem
            key={item.id}
            item={item}
            name={`${name}.${index}`}
            handleDelete={(idToDel) => {
              setItemToDelete(idToDel);
              setDeleteDialogOpen(true);
            }}
          />
        ))}
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Button variant="contained" color="secondary" onClick={handleAddGroup}>
          Dodaj Q&A
        </Button>
      </Grid>
      <ConfirmationDialog
        open={deleteDialogOpen}
        close={() => {
          setItemToDelete(null);
          setDeleteDialogOpen(false);
        }}
        confirm={handleDeleteGroup}
      />
    </>
  );
};

export default GroupFaqItem;
