import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { HEAD_CELL_TYPES } from "../../enums/headCellTypes";

import { Checkbox } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import * as color from "assets/styles/jss/common/colors";
import * as font from "assets/styles/jss/common/fonts";
type DataTableHeadProps = {
  headCells: any;
  classes: any;
  order: any;
  orderBy: any;
  onRequestSort: any;
  noCheckbox: boolean;
  handleCheckAllCheckboxes?: any;
  checkedCheckboxAll?: boolean;
};
const DataTableHead: FC<DataTableHeadProps> = ({
  headCells,
  classes,
  order,
  orderBy,
  onRequestSort,
  noCheckbox,
  handleCheckAllCheckboxes,
  checkedCheckboxAll,
}) => {
  const { t } = useTranslation("common");

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          borderBottom: "2px solid " + color.black,
          "& th": {
            fontWeight: font.bold,
          },
        }}
      >
        {!noCheckbox && (
          <TableCell>
            <Checkbox size={"small"} onClick={handleCheckAllCheckboxes} checked={checkedCheckboxAll} />
          </TableCell>
        )}
        {headCells.map((headCell, index) =>
          headCell.type === HEAD_CELL_TYPES.LANGUAGE_ACTION ? (
            headCell.label.split(";").map((cell) => (
              <TableCell key={headCell.id + "-" + cell} padding={"checkbox"}>
                {cell}
              </TableCell>
            ))
          ) : (
            <TableCell
              key={headCell.id + "-" + index}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.noPadding}
            >
              {headCell.sortable && (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {t(headCell.label)}
                  {/*orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span> : null*/}
                </TableSortLabel>
              )}
              {!headCell.sortable && t(headCell.label)}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
};

export default DataTableHead;
