import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

import { Grid, Paper } from "@mui/material";

import backend from "api/backend";

import EditViewNoLanguage from "components/EditViewNoLanguage";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";

const endpoint = "/language/";
const title = "settings_language_edit_label";
const moduleUrl = "/settings/languages/";

const LanguageEdit: FC = () => {
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    languageCode: Yup.string().trim().required(t("form-validation-required")),
    active: Yup.bool().required(t("form-validation-required")),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        noDeleteButton
      >
        <FormData />
      </EditViewNoLanguage>
    </>
  );
};

const FormData = ({}) => {
  const { t } = useTranslation("common");
  const [langs, setLangs] = useState([{ id: "pl", displayName: "Polski" }]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await backend.get(`/language/all-available`);
      if (res.status === 200) {
        const arr = res.data.map((e) => ({ id: e.code, displayName: e.name }));
        console.error(arr);
        setLangs(arr);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect label={t("language")} name={"languageCode"} items={langs} defaultValue={"pl"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSwitch name={"active"} rightLabel={t("active")} topLabel={"Status"} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default LanguageEdit;
