import React, { FC } from "react";

import TableCell from "@mui/material/TableCell";
import { alpha } from "@mui/material/styles";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import * as color from "assets/styles/jss/common/colors";
type CellAttachmentProps = {
  data: any;
  handleClick?: (e: any, row: any) => void;
  row?: any;
};
const CellAttachment: FC<CellAttachmentProps> = ({ data, handleClick, row }) => {
  return (
    <TableCell onClick={(e) => (handleClick && row ? handleClick(e, row) : null)}>
      {data && <AttachFileIcon fontSize={"small"} sx={{ color: alpha(color.black, 0.7) }} />}
    </TableCell>
  );
};

export default CellAttachment;
