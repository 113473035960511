import React, { FC, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import backend from "api/backend";

import { setUserLogged } from "redux/slices/user";

import ConfirmationCodeField from "components/ConfirmationCodeField";

import { isSuccess } from "utils/http";

import * as color from "assets/styles/jss/common/colors";

const Login: FC = () => {
  const { t } = useTranslation("common");
  const [submitButtonEnable, setSubmitButtonEnable] = useState(true);
  const [error, setError] = useState(false);
  const [fa, setFa] = useState(false);
  const dispatch = useDispatch();

  const ref = useRef<HTMLElement & { error: () => void }>(null);

  const defaultValues = {
    login: "",
    password: "",
  };

  const [values, setValues] = useState({});

  const handleError = (status) => {
    if (status === 405) {
      setFa(true);
    } else if (status === 412 && ref) {
      ref.current?.error();
    } else {
      setFa(false);
      setError(true);
      setSubmitButtonEnable(true);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  const handleConfirmationCodeSubmit = (value) => {
    backend
      .post("/user/login", { ...values, confirmationCode: value })
      .then((response) => {
        if (isSuccess(response)) {
          dispatch(setUserLogged(true));
        } else {
          handleError(response.status);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onSubmit = (d) => {
    setValues(d);
    setSubmitButtonEnable(false);
    backend
      .post("/user/login", d)
      .then((response) => {
        if (isSuccess(response)) {
          dispatch(setUserLogged(true));
        } else {
          handleError(response.status);
        }
      })
      .catch((err) => {
        console.info(err);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    criteriaMode: "all",
    // "resolver": yupResolver(validationSchema),
  }); // initialize the hook

  return (
    <Grid container spacing={5} direction="column" alignItems="center" justifyContent="center" minHeight={"100vh"}>
      <Grid item xs={4}>
        <Paper sx={{ p: 10, mb: 10, textAlign: "center" }} elevation={8}>
          {error && (
            <Typography variant={"h5"} style={{ background: color.lightRed, color: color.red, marginBottom: 20 }}>
              {t("login_error_bad_login_or_password")}
            </Typography>
          )}
          {!fa ? (
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <img src="/logo.png" style={{ maxWidth: 300, marginBottom: 20 }} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register("login")}
                    name="login"
                    label={t("login_login")}
                    variant="outlined"
                    helperText={errors.login ? errors.login.message : ""}
                    error={!!errors.login}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...register("password")}
                    type="password"
                    label={t("login_password")}
                    variant="outlined"
                    id="outlined-required"
                    helperText={errors.password ? errors.password.message : ""}
                    error={!!errors.password}
                    fullWidth
                  />
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*<a className={classes.btnFirst} onClick={() => history.push('/remind-password')}>*/}
                {/*  Forgot password*/}
                {/*</a>*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="secondary"
                    disabled={!submitButtonEnable}
                    disableElevation={true}
                  >
                    {t("login_sigin")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : (
            <ConfirmationCodeField ref={ref} handleConfirmationCodeSubmit={handleConfirmationCodeSubmit} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
export default Login;
