import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Paper } from "@mui/material";

import HeaderBuilder from "layout/Header/HeaderBuilder";
import Layout from "layout/Layout";

import DataTable from "components/Table/DataTable";

const toolbarItems = [
  {
    name: "toolbar_search_field",
    tooltip: "toolbar_search_field_tooltip",
    icon: "Search",
    action: "search",
    disabled: false,
    sortOrder: 4,
    type: "FIELDS_SEARCH",
    searchFieldName: "name:like,description:like,ownerName:like,ownerLogin:like",
  },
];

const SharedForMeDocumentList: FC = () => {
  const { t } = useTranslation("common");

  return (
    <Layout
      header={t("document_shared_label")}
      top={<HeaderBuilder headerData={toolbarItems} moduleUrl={"/documents/shared-for-me"} />}
    >
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <DataTable modulePath={"/documents/shared-for-me"} endpoint={"/documents-shared/"} />
      </Paper>
    </Layout>
  );
};
export default SharedForMeDocumentList;
