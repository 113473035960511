import React, { ForwardRefRenderFunction, forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";

import * as Yup from "yup";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid, InputLabel, Paper, Typography } from "@mui/material";

import backend from "api/backend";

import EditViewNoLanguage from "components/EditViewNoLanguage";

import { isSuccess } from "utils/http";
import getBackendHost from "utils/backendUrl";
import DetailsTable from "modules/settings/Documents/DetailsTable";

const endpoint = "/documents-shared/";
const title = "document_shared_edit_label";
const moduleUrl = "/documents/shared-for-me/";

const SharedDocument: ForwardRefRenderFunction<HTMLTextAreaElement, any> = () => {
  const { id } = useParams<{ id: string }>();

  const validationSchema = Yup.object({});

  const refForm = useRef(null);
  const childRef = useRef<HTMLElement & { uploadFile: () => void }>(null);

  return (
    <>
      <EditViewNoLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        customSaveFunc={() => childRef?.current?.uploadFile()}
        noDeleteButton
        noSaveButton
      >
        <FormDataCustom id={id} refForm={refForm} ref={childRef} />
      </EditViewNoLanguage>
    </>
  );
};

// eslint-disable-next-line react/display-name
const FormDataCustom = forwardRef<any, { id: string; refForm: any; ref: any }>((props) => {
  const { id } = props;

  const { watch } = useFormContext();

  const { t } = useTranslation("common");
  const watchName = watch("name");
  const watchVersions = watch("versions");
  const watchDescription = watch("description");
  const watchOwner = watch("owner");

  const handleDownload = async (version: number, fileExtension: string, originalName) => {
    backend
      .get(`${getBackendHost()}${endpoint}get-file/${id}/${version}`)
      .then((response) => {
        if (isSuccess(response)) {
          const blob = new Blob([response.data], {
            type: fileExtension,
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", watchName ? watchName : originalName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((reason) => console.error(reason));
  };

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12}>
            <InputLabel>{t("document_own_edit_file_name")}</InputLabel>
            <Typography>{watchName}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel>{t("document_own_edit_description")}</InputLabel>
            <Typography>{watchDescription}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputLabel>{t("document_own_edit_owner")}</InputLabel>
            <Typography>{`${watchOwner?.name} (${watchOwner?.login})`}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Accordion square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ p: 0 }}
          >
            <Typography variant="h4" component={"h4"}>
              {t("document_own_edit_details")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mb: 5 }}>
            <DetailsTable versions={watchVersions} handleDownload={handleDownload} />
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
});

export default forwardRef(SharedDocument);
