import React, { FC, useRef } from "react";
import { useSnackbar } from "notistack";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { mainPanelInput, white } from "assets/colors";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useTranslation } from "react-i18next";

type ChatNewMessageProps = {
  sendMessage: (message: string, ref: any) => void;
  openSettings: () => void;
  setFullscreen: (value: any) => void;
  isFullscreen: boolean;
};

const ChatNewMessage: FC<ChatNewMessageProps> = ({ sendMessage, openSettings, setFullscreen, isFullscreen }) => {
  const { t } = useTranslation("common");
  const newMessageTextFieldRef = useRef();
  const { enqueueSnackbar } = useSnackbar();

  const send = () => {
    if (newMessageTextFieldRef.current) {
      const current: any = newMessageTextFieldRef.current;

      if (current.value.trim() !== "") {
        sendMessage(current.value, newMessageTextFieldRef);
      } else {
        enqueueSnackbar(t("knowledge_base_enter_question"), { variant: "warning" });
      }
    }
  };

  const keyPressed = (e) => {
    if (e.key === "Enter" && newMessageTextFieldRef.current !== null) {
      e.preventDefault();
      send();
    }
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <TextField
        id="newMessageTextField"
        label={t("knowledge_base_new_message")}
        variant="filled"
        multiline
        maxRows={3}
        fullWidth
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: mainPanelInput,
            border: "none",
            "&:after": {
              borderBottomColor: mainPanelInput,
            },
          },
          "& .MuiInputLabel-root": {
            color: white,
          },
          "& .MuiInputBase-input": {
            color: white,
          },
        }}
        InputLabelProps={{
          style: { color: "#fff" },
        }}
        inputRef={newMessageTextFieldRef}
        onKeyDown={keyPressed}
      />
      <IconButton onClick={send}>
        <SendIcon fontSize="small" sx={{ color: white }} />
      </IconButton>
      <IconButton onClick={openSettings}>
        <SettingsIcon fontSize="small" sx={{ color: white }} />
      </IconButton>
      <IconButton
        onClick={() =>
          setFullscreen((prev) => {
            return !prev;
          })
        }
      >
        {isFullscreen ? <CloseFullscreenIcon sx={{ color: white }} /> : <FullscreenIcon sx={{ color: white }} />}
      </IconButton>
    </Stack>
  );
};

export default ChatNewMessage;
