import React from "react";
import { useHistory } from "react-router-dom";

import { Button, Hidden, IconButton, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import createStyles from "@mui/styles/createStyles";
import styles from "./styles";

const useStyles = makeStyles<Theme>(createStyles(styles));

const Header = ({ children, mobileToggle, mobileOpened, actionButton, top }) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <>
      <div className={classes.header}>
        <div className={classes.upperSection}>
          {/* <Breadcrumbs /> */}
          <Hidden mdUp>
            <IconButton
              color="inherit"
              aria-label="open mobile menu"
              onClick={mobileToggle}
              className={classes.smallButton}
              size="large"
            >
              {!mobileOpened && <MenuIcon />}
              {mobileOpened && <CloseIcon />}
            </IconButton>
          </Hidden>
        </div>
        <div className={classes.label}>
          <Typography variant="h1">{children}</Typography>
        </div>
        <div className={classes.actions}>{top}</div>
        <div className={classes.actionButton}>
          {actionButton && (
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => {
                history.push(actionButton);
              }}
            >
              dodaj className={classes.actionButton}
            </Button>
          )}
        </div>
      </div>
      {/*{bottom}*/}
    </>
  );
};

export default Header;
