import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useSnackbar } from "notistack";

import TableCell from "@mui/material/TableCell";
import { Paper, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

import backend from "api/backend";

import { selectUser } from "redux/slices/user";

import NoLaboratoryFragment from "components/Table/Inbox/NoLaboratoryFragment";
import LaboratoryFragment from "components/Table/Inbox/LaboratoryFragment";
import ConfirmationDialog from "components/ConfirmationDialog";
import AcceptNodeFragment from "components/Table/Inbox/AcceptNodeFragment";

import { LABORATORY_CODES } from "enums/laboratoryRoleCodes";

import { isSuccess } from "utils/http";

const CellInboxType = ({ data, row, getData }) => {
  const [fullView, setFullView] = useState(false);
  const [isLaboratory, setIsLaboratory] = useState<boolean | null>(null);
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [acceptCode, setAcceptCode] = useState(null);
  const [isAcceptNodeAvailable, setIsAcceptNodeAvailable] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      const testAndSetIsLaboratory = () => {
        for (const role of user.roles) {
          if (LABORATORY_CODES.includes(role)) {
            return true;
          }
        }
        return false;
      };
      setIsLaboratory(testAndSetIsLaboratory());
    }
  }, [user]);

  useEffect(() => {
    if (isLaboratory !== null) {
      if (isLaboratory === false) {
        setIsAcceptNodeAvailable(true);
      } else {
        let isAllFilesUploaded = true;

        for (const element of data.mapAttributes) {
          if (element.value !== "Załadowany") {
            isAllFilesUploaded = false;
          }
        }

        setIsAcceptNodeAvailable(isAllFilesUploaded);
      }
    }
  }, [isLaboratory]);

  const acceptNode = () => {
    if (isAcceptNodeAvailable === false) {
      return;
    }

    setIsOpenConfirmDialog(false);

    const d = { taskId: row.id, acceptCode: acceptCode };

    backend.post("/inbox/accept", d).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        getData();
      } else {
        getData();
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.data.message, {
          variant: "error",
        });
      }
    });
  };

  return (
    <>
      <TableCell onClick={() => setFullView(true)}>
        {!fullView && data.name}
        {fullView && (
          <>
            <Stack
              direction={"row"}
              justifyContent={"center"}
              onClick={(e) => {
                e.stopPropagation();
                setFullView(false);
              }}
            >
              <Typography>{data.name}</Typography>
              <br />
            </Stack>
            <Paper sx={{ p: 5, mb: 5 }} variant="outlined" dangerouslySetInnerHTML={{ __html: data?.currentState }} />
            {isLaboratory === true ? (
              <LaboratoryFragment
                fullView={fullView}
                data={data}
                row={row}
                setIsAcceptNodeAvailable={setIsAcceptNodeAvailable}
              />
            ) : (
              <NoLaboratoryFragment fullView={fullView} data={data} />
            )}
            <AcceptNodeFragment
              data={data}
              fullView={fullView}
              setFullView={setFullView}
              setAcceptCode={setAcceptCode}
              setIsOpenConfirmDialog={setIsOpenConfirmDialog}
              isAcceptNodeAvailable={isAcceptNodeAvailable}
            />
          </>
        )}
      </TableCell>
      <ConfirmationDialog
        confirm={acceptNode}
        open={isOpenConfirmDialog}
        close={() => setIsOpenConfirmDialog(false)}
        customTitle={"confirmation_modal_title_action"}
      />
    </>
  );
};

export default CellInboxType;
