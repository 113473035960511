import React from "react";

import TableCell from "@mui/material/TableCell";
import { alpha } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import * as color from "assets/styles/jss/common/colors";

const MoveCell = ({ drag }) => {
  return (
    <TableCell align={"right"}>
      <Tooltip title={"Przesuń, aby zmienić kolejność"} placement="right-end" arrow={true} sx={{ mr: "10px" }}>
        <IconButton ref={drag} style={{ cursor: "move" }}>
          <DragIndicatorIcon fontSize={"small"} sx={{ color: alpha(color.black, 0.4) }} />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

export default MoveCell;
