import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

import { Grid, Paper } from "@mui/material";

import CustomizedTextField from "components/Edit/CustomizedTextField";
import EditViewLanguage from "components/EditViewLanguage";
import GroupFaqItem from "components/Edit/Faq/GroupFaqItem";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";

const endpoint = "/faq/";
const title = "cms_faq_edit_label";
const moduleUrl = "/cms/faq/";

const FaqEdit: FC = () => {
  const { t } = useTranslation("common");

  const validationSchema = Yup.object({
    text: Yup.string().trim().required(t("form-validation-required")),
    offline: Yup.bool().nullable(),
    qa: Yup.array(
      Yup.object({
        id: Yup.string().nullable(),
        question: Yup.string().nullable(),
        answer: Yup.string().nullable(),
      }),
    ),
  });

  const defaultValue = {
    text: "",
    offline: "true",
    qa: [],
  };

  const refForm = useRef(null);

  return (
    <>
      <EditViewLanguage
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        preview={false}
        defaultValue={defaultValue}
      >
        <FormData />
      </EditViewLanguage>
    </>
  );
};

const FormData = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <CustomizedTextField label={"Nazwa"} name={"text"} />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSwitch
              name={"offline"}
              rightLabel={t("cms_edit_page_offline")}
              topLabel={t("cms_edit_page_state")}
            />
          </Grid>
          <GroupFaqItem />
        </Grid>
      </Paper>
    </>
  );
};

export default FaqEdit;
