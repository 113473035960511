import React, { FC, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";

import backend from "../../api/backend";
import { isSuccess } from "../../utils/http";

import CircularProgress from "@mui/material/CircularProgress";

import HeaderBuilder from "layout/Header/HeaderBuilder";
import Layout from "layout/Layout";

import {
  fetchCrudItem,
  selectListError,
  selectListIsLoading,
  clearCurrentItem,
  fetchCrudList,
  selectEditItem,
  selectListFilters,
  setListPage,
} from "redux/slices/crud";
import { selectMenuItems, setCurrentSelectedLevelOne, setCurrentSelectedLevelZero } from "redux/slices/menu";

import EditActionButtons from "components/Edit/EditActionButtons";
import ConfirmationDialog from "components/ConfirmationDialog";
import Error from "components/Error";

import { findMenu } from "utils/menu";

type EditViewNoLanguageProps = {
  children: React.ReactNode;
  endpoint: string;
  title: string;
  moduleUrl: string;
  validationSchema: any;
  onSubmit?: any;
  refForm?: any;
  preview?: boolean;
  testMessage?: () => void;
  noDeleteButton?: boolean;
  noBackButton?: boolean;
  noSaveButton?: boolean;
  customSaveFunc?: any;
  actionIndexKnowledge?: () => void;
  headerData?: any;
};

const EditViewNoLanguage: FC<EditViewNoLanguageProps> = ({
  children,
  endpoint,
  title,
  moduleUrl,
  validationSchema,
  onSubmit,
  refForm = useRef(null),
  preview,
  testMessage,
  noDeleteButton,
  noSaveButton,
  noBackButton,
  customSaveFunc = null,
  actionIndexKnowledge,
  headerData = [],
}) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectListIsLoading);
  const error = useSelector(selectListError);
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const filters = useSelector(selectListFilters);
  const [blockSetFields, setBlockSetFields] = useState(true);
  const [ignoreError, setIgnoreError] = useState(false);

  const [openedRemoveDialog, setOpenedRemoveDialog] = useState(false);

  const current = useSelector(selectEditItem);

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  }); // initialize the hook

  useEffect(() => {
    dispatch(clearCurrentItem());
    if (id === "new") {
      setIgnoreError(true);
    } else {
      dispatch(fetchCrudItem({ endpoint, id }));
      setBlockSetFields(false);
      setIgnoreError(false);
    }
  }, [id]);

  const menuItems = useSelector(selectMenuItems);
  useEffect(() => {
    const { levelZero, levelOne } = findMenu(menuItems);
    dispatch(setCurrentSelectedLevelZero(levelZero));
    dispatch(setCurrentSelectedLevelOne(levelOne));
  }, [menuItems]);

  useEffect(() => {
    if (current && !blockSetFields) {
      Object.keys(current).forEach((k) => {
        methods.setValue(k, current[k]);
      });
    }
  }, [current]);

  useEffect(() => {
    if (id !== "new") {
      setBlockSetFields(false);
      setIgnoreError(false);
    }
  }, [id]);

  /*TO DO*/
  const handlePreview = () => {
    console.info("Podgląd");
  };

  const handleSave = () => {
    console.info("Save");
    refForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const handleSubmit = (d) => {
    console.info(d);

    if (typeof onSubmit === "function") {
      const data = onSubmit(d);
      console.info(data);
      _onSubmit(data);
    } else {
      _onSubmit(d);
    }
  };

  const _onSubmit = (d) => {
    let idTmp: any = id;

    if (id === "new" || current === null) {
      idTmp = 0;
    }

    backend.post(endpoint + idTmp, d).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        if (res.data.item !== null) {
          if (idTmp === 0) {
            const newId = res.data.item.id;
            dispatch(fetchCrudItem({ endpoint, id: newId }));
            history.push(moduleUrl + newId);
          } else {
            dispatch(fetchCrudItem({ endpoint, id }));
          }
        } else {
          history.push(moduleUrl);
        }
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.data.message, {
          variant: "error",
        });
      }
    });
  };

  const handleOpenDeleteDialog = () => {
    setOpenedRemoveDialog(true);
  };

  const handleDeleteFromDB = () => {
    console.info("Delete");

    backend.delete(endpoint + id).then((res) => deleteResponseFunction(res));

    setOpenedRemoveDialog(false);
  };

  const deleteResponseFunction = (res) => {
    if (isSuccess(res)) {
      enqueueSnackbar(t("common_status_successfully"), {
        variant: "success",
      });
      dispatch(setListPage(0));
      dispatch(fetchCrudList({ endpoint, filters }));
      history.push(moduleUrl);
    } else {
      enqueueSnackbar(t("common_status_error") + res.status + "/" + res.data.message, {
        variant: "error",
      });
    }
  };

  return (
    <Layout
      header={t(title)}
      top={
        <HeaderBuilder
          headerData={headerData}
          isEditViewActive={true}
          moduleUrl={moduleUrl}
          noBackButton={noBackButton}
          actionIndexKnowledge={actionIndexKnowledge}
        />
      }
    >
      <FormProvider {...methods} reset={handleOpenDeleteDialog}>
        {isLoading && !ignoreError ? (
          <CircularProgress />
        ) : error.isError && !ignoreError ? (
          <Error error={error} />
        ) : !error.isError && current === null && !ignoreError ? (
          <Error error={{ message: "no data" }} />
        ) : (
          <>
            <form ref={refForm} noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
              {children}
            </form>
            <EditActionButtons
              handleSave={typeof customSaveFunc === "function" ? customSaveFunc : handleSave}
              handleDelete={handleOpenDeleteDialog}
              handlePreview={preview ? handlePreview : undefined}
              handleTest={testMessage ? testMessage : undefined}
              noDeleteButton={noDeleteButton || id === "new"}
              noSaveButton={noSaveButton}
            />
          </>
        )}
      </FormProvider>
      <ConfirmationDialog
        open={openedRemoveDialog}
        close={() => setOpenedRemoveDialog(false)}
        confirm={handleDeleteFromDB}
      />
    </Layout>
  );
};

export default EditViewNoLanguage;
