import { CircularProgress, Grid, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "redux/hooks";
import { fetchMenu } from "redux/slices/menu";
import {
  fetchChangeOrganization,
  selectHasOrganizationChanged,
  selectOrganizationsList,
  selectOrganizationsLoading,
  setOrganizationChangedFalse,
} from "redux/slices/organizations";
import { fetchCurrentUser, selectUser } from "redux/slices/user";
import { useHistory } from "react-router-dom";

export default function OrganizationInfo() {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const organizations = useSelector(selectOrganizationsList);
  const isLoading = useSelector(selectOrganizationsLoading);
  const hasOrganizationChanged = useSelector(selectHasOrganizationChanged);
  const history = useHistory();

  useEffect(() => {
    if (hasOrganizationChanged) {
      dispatch(fetchCurrentUser());
      dispatch(fetchMenu());
      dispatch(setOrganizationChangedFalse());
      history.push("/");
    }
  }, [hasOrganizationChanged]);

  const onChange = (e) => {
    dispatch(fetchChangeOrganization({ id: e.target.value }));
  };

  return (
    <Box>
      {user.organization?.id && (
        <Grid item xs={12} ml={2} mr={2}>
          {isLoading && <CircularProgress />}
          <Select onChange={onChange} value={user.organization?.id} displayEmpty={false} sx={{ minWidth: "200px" }}>
            {organizations?.map((item: { id: number; shortName: string }) => (
              <MenuItem key={item.id} value={item.id}>
                {item.shortName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      )}
    </Box>
  );
}
