import { useDispatch, useSelector } from "react-redux";
import React, { Fragment } from "react";

import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import backend from "api/backend";
import { useSnackbar } from "notistack";

import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, Box, Menu } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { selectUser, setUserLogged } from "redux/slices/user";

import { isSuccess } from "utils/http";

import styles from "./styles";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles(createStyles(styles));

{
  /* <Box sx={{ flexGrow: 0 }}>
              
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> */
}

const ProfileInfo = () => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 35,
        height: 35,
        marginRight: 2,
      },
      children: name.length > 0 ? name[0].toUpperCase() : "?",
    };
  }

  const handleLogout = (popupState) => {
    popupState.close();
    backend
      .post("/user/logout")
      .then((response) => {
        if (isSuccess(response) && response.data === "OK") {
          dispatch(setUserLogged(false));
          enqueueSnackbar("Wylogowano poprawnie", { variant: "success" });
        } else {
          enqueueSnackbar("Błąd wylogowania", { variant: "error" });
        }
      })
      .catch((err) => {
        console.info(err);
      });
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Box className={classes.user}>
        <PopupState variant="popover" popupId="demoMenu">
          {(popupState) => (
            <Fragment>
              <Tooltip title={`${user?.userName} - Open settings`}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  alignContent={"center"}
                  sx={{ cursor: "pointer" }}
                  {...bindTrigger(popupState)}
                >
                  <Avatar {...stringAvatar(user?.userName ? user?.userName : "")} alt={user?.login} />
                </Stack>
              </Tooltip>
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    console.info("go to profile");
                  }}
                  sx={{ width: "150px" }}
                >
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    console.info("go to profile");
                  }}
                >
                  Dashboard
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleLogout(popupState);
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </Fragment>
          )}
        </PopupState>
      </Box>
    </Box>
  );
};
export default ProfileInfo;
