import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";

export const fetchMenu = createAsyncThunk("menu/fetchMenu", async () => {
  const url = `/menu/`;
  const res = await backend.get(url);
  return res.data;
});

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    modules: [],
    items: [],
    currentSelectedLevelZero: 0,
    currentSelectedLevelOne: "",
    modulePath: "",
    isLoading: false,
  },
  reducers: {
    setCurrentSelectedLevelZero(state, action) {
      state.currentSelectedLevelZero = action.payload;
    },
    setCurrentSelectedLevelOne(state, action) {
      state.currentSelectedLevelOne = action.payload;
    },
    setModulePath(state, action) {
      state.modulePath = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenu.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(fetchMenu.rejected, (state) => {
        state.isLoading = false;
      }),
      builder.addCase(fetchMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.modules = action.payload;
        state.items = action.payload ? action.payload.filter((m) => m.code === "SETTINGS")[0].menu : [];
      });
  },
});

export const selectMenuItems = (state) => state.menu.items;
export const selectModuleItems = (state) => state.menu.modules;
export const selectCurrentSelectedLevelZero = (state) => state.menu.currentSelectedLevelZero;
export const selectCurrentSelectedLevelOne = (state) => state.menu.currentSelectedLevelOne;
export const selectModulePath = (state) => state.menu.modulePath;
export const { setCurrentSelectedLevelZero, setCurrentSelectedLevelOne, setModulePath } = menuSlice.actions;

export default menuSlice.reducer;

// const items = [
//   {
//     name: "Start",
//     icon: "HOME_ICON",
//     selected: true,
//     group: [{ name: "Start", pages: [{title: "Inbox", path: "/home/inbox", icon: "inbox"}, {title: "Twoje skróty", path: "/home/shortcuts", icon: "shortcut"}] }],
//   },
//   {
//     name: "CMS",
//     icon: "DESCRIPTION_ICON",
//     selected: false,
//     group: [
//       { name: "Dokumenty", pages: [{title: "Strony", path: "/cms/pages", icon: 'page'}, {title: "Wpisy", path: "/cms/posts", icon: 'article'}, {title: "Kategorie", path: "/cms/categories", icon: 'category'}, {title: "Komentarze", path: "/cms/comments", icon: 'comment'}] },
//       { name: "Zasoby", pages: [{title: "Media", path: "/cms/media", icon: "media"}] },
//       { name: "Komponenty", pages: [{title: "FAQ", path: "/cms/faq", icon: "faq"}, {title: "Menu", path: "/cms/menu", icon: 'menu'}, {title: "Slajdery", path: "/cms/sliders", icon: 'panorama'}, {title: "Stopki", path: "/cms/footers", icon: 'footer'}] },
//     ],
//   },
//   {
//     name: "Sklep",
//     icon: "SHOPPING_CART_ICON",
//     selected: false,
//     group: [
//       { name: "Zamówienia", pages: [{icon: "people", title: "Klienci", path: "/shop/customers"}, {title: "Zamówienia", path: "/shop/orders", icon: "list"}, {title: "Faktury", path: "/shop/invoices", icon: "receipt"}] },
//       {
//         name: "Powiadomienia",
//         pages: [{title: "Formularze kontaktowe", path: "/shop/contact-forms", icon: 'contact'}, {title: "Zapytania o produkt", path: "/shop/product-inquiries", icon: 'question'}, {title: "Zwroty i reklamacje", path: "/shop/complaints", icon: "back"}, {title: "Komunikaty systemowe", path: "/shop/system-messages", icon: 'mail'}],
//       },
//       { name: "Katalog", pages: [{title: "Produkty", path: "/shop/products", icon: 'store'}, {title: "Kategorie", path: "/shop/categories", icon: 'category'}] },
//     ],
//   },
//   {
//     name: "Marketing",
//     icon: "FLASH_ON_ICON",
//     selected: false,
//     group: [
//       { name: "Biblioteka", pages: [{title: "Procesy", path: "/marketing/processes", icon: "schema"}, {title: "Emaile", path: "/marketing/mail", icon: 'mail'}, {title: "Sms", path: "/marketing/sms", icon: 'sms'}, {title: "Popupy", path: "/marketing/popups", icon: 'page'}, {title: "Zgody marketingowe", path: "/marketing/consents", icon: 'people'}, {title: "Kody rabatowe", path: "/marketing/discount-codes", icon: 'discount'}] },
//     ],
//   },
//   {
//     name: "Ustawienia",
//     icon: "SETTINGS_ICON",
//     selected: false,
//     group: [
//       { name: "Dostęp", pages: [{title: "Użytkownicy", path: "/settings/users", icon: 'person'}, {title: "Role", path: "/settings/roles", icon: 'group'}] },
//       { name: "Integracje", pages: [{title: "Wysyłka SMS", path: "/settings/sms/pl", modulePath: '/settings/sms', icon: 'sms'}, {title: "Wysyłka email", path: "/settings/mail/pl", modulePath : "/settings/mail", icon: 'mail'}] },
//       {
//         name: "Konfiguracja",
//         pages: [
//           {title: "Języki", path: "/settings/languages", icon: "language"},
//           {title: "Tłumaczenie wyrażeń", path: "/settings/translations", icon: "translate"},
//           {title: "Konfiguracja", path: "/settings/configuration", modulePath: "/settings/configuration/pl", icon: 'settings'},
//           {title: "Kody analityczne", path: "/settings/codes/pl", modulePath: "/settings/codes", icon: 'analytics'},
//           {title: "Przekierowania", path: "/settings/redirects", icon: 'link'},
//           {title: "Generator sitemapy", path: "/settings/sitemap", icon: 'sitemap'},
//           {title: "Log", path: "/settings/log", icon: 'list'}
//         ],
//       },
//     ],
//   },
// ];
