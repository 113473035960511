import React, { FC, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import Switch from "@mui/material/Switch";
import { FormControlLabel, FormGroup, InputLabel } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

type CustomizedSwitchProps = {
  topLabel?: string;
  rightLabel?: string;
  name: string;
  disabled?: boolean;
  defaultValue?: boolean;
  noNullValue?: boolean;
};

const CustomizedSwitch: FC<CustomizedSwitchProps> = ({
  topLabel = "",
  rightLabel = "",
  name,
  disabled = false,
  defaultValue = true,
}) => {
  const { control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, name, ref }, fieldState: {}, formState }) => (
          <>
            <InputLabel>{topLabel}</InputLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={value === true || value === "true" || value === "PUBLISHED"}
                    color="secondary"
                    inputRef={ref}
                    onChange={onChange}
                    disabled={disabled}
                  />
                }
                label={rightLabel}
              />
              <FormHelperText>{formState?.errors[name] ? "" + formState?.errors[name]?.message : ""}</FormHelperText>
            </FormGroup>
          </>
        )}
      />
    </>
  );
};

export default memo(CustomizedSwitch);
