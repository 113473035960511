import {
  leftPanelBackground,
  leftPanelAddButton,
  leftPanelstatusBarOpen,
  leftPanelstatusBarClose,
} from "assets/colors";
import React, { Dispatch, FC, SetStateAction, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import ChatBox from "modules/KnowledgeBase/components/left/ChatBox";
import { ShortCategoryType, ShortChatType } from "modules/KnowledgeBase/types/types";
import SelectCategory from "./left/SelectCategory";

type ChatLeftPanel = {
  chats: ShortChatType[];
  selectedChat: string;
  handleChangeChat: (chat: ShortChatType) => void;
  handleDeleteChat: (chat: ShortChatType, e: any) => void;
  handleAddChat: () => void;
  connectionStatus: string;
  allCategories: ShortCategoryType[];
  setSelectedCategory: Dispatch<SetStateAction<string>>;
  selectedCategory: string;
};

const ChatLeftPanel: FC<ChatLeftPanel> = ({
  chats,
  handleAddChat,
  handleChangeChat,
  selectedChat,
  handleDeleteChat,
  connectionStatus,
  allCategories,
  setSelectedCategory,
  selectedCategory,
}) => {
  const { t } = useTranslation("common");
  const cellHandleDeleteChat = useCallback((chat, e) => {
    handleDeleteChat(chat, e);
  }, []);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      width="15vw"
      height="80vh"
      sx={{ background: leftPanelBackground, marginY: "0", marginX: "0" }}
    >
      <Box height="10vh" display="flex" alignItems="center">
        <Button
          size="large"
          variant="contained"
          sx={{ paddingX: "40px", borderRadius: "20px", background: leftPanelAddButton }}
          onClick={handleAddChat}
        >
          {t("knowledge_base_new")}
        </Button>
      </Box>
      <Box
        height="3vh"
        display="flex"
        alignItems="center"
        sx={{
          background: connectionStatus === "Open" ? leftPanelstatusBarOpen : leftPanelstatusBarClose,
          width: 1,
          textAlign: "center",
        }}
      >
        <Typography fontSize="0.65rem" sx={{ width: 1, textAlign: "center" }}>
          {t("knowledge_base_connection")} {connectionStatus}
        </Typography>
      </Box>
      <Box width="100%" marginY={1}>
        <SelectCategory
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          allCategories={allCategories}
        />
      </Box>
      <Box height="87vh" sx={{ overflowY: "auto" }}>
        <ChatBox
          chats={chats}
          selectedChat={selectedChat}
          handleChangeChat={handleChangeChat}
          handleDeleteChat={cellHandleDeleteChat}
        />
      </Box>
    </Stack>
  );
};

export default ChatLeftPanel;
