import React, { FC, memo } from "react";
import { useFormContext } from "react-hook-form";

import TextField from "@mui/material/TextField";
import { Grid, InputLabel } from "@mui/material";

type CustomizedTextFieldPropsType = {
  label: string;
  name: string;
  disabled?: boolean;
  type?: string;
  defaultValue?: string;
  step?: number;
  rows?: number;
};

const CustomizedTextField: FC<CustomizedTextFieldPropsType> = ({
  label,
  name,
  disabled = false,
  type = "text",
  defaultValue = "",
  step = 1,
  rows,
}) => {
  const { register, formState } = useFormContext();

  return (
    <>
      <Grid item xs={12}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <TextField
          {...register(name)}
          helperText={formState?.errors[name] ? <>{formState?.errors[name]?.message}</> : ""}
          error={!!formState?.errors[name]}
          variant="outlined"
          size="small"
          fullWidth={true}
          disabled={disabled}
          type={type}
          inputProps={type === "number" ? { min: 0, step: step } : {}}
          defaultValue={defaultValue}
          multiline={!!rows}
          rows={!!rows ? rows : undefined}
        />
      </Grid>
    </>
  );
};

export default memo(CustomizedTextField);
