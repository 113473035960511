import React, { FC, useState } from "react";
import { TreeViewProps } from "components/TreeView/types";
import TreeViewItem from "components/TreeView/TreeViewItem";

const TreeViewComponent: FC<TreeViewProps> = ({
  config,
  data,
  handleEditElement,
  handleDeleteElement,
  handleAddElement,
  handleDropElement,
  checkboxed,
  onToggleCheckbox,
}) => {
  const [selected, setSelected] = useState<string | undefined>();
  const [expanded, setExpanded] = useState<number[]>([]);

  const handleRowClick = (idString: string) => {
    setSelected(idString);
  };

  const handleToggleExpanded = (id: number) => {
    const index = expanded.indexOf(id);
    if (index > -1) {
      setExpanded(expanded.filter((item) => item !== id));
    } else {
      setExpanded([...expanded, id]);
    }
  };

  return (
    <>
      {data.map((item) => (
        <TreeViewItem
          tree={data}
          config={config}
          key={`tree-item-${item.id}-${item.type}`}
          {...item}
          selectedId={selected}
          onRowClick={handleRowClick}
          onToggleExpanded={handleToggleExpanded}
          open={expanded}
          checkboxed={checkboxed}
          onToggleCheckbox={onToggleCheckbox}
          handleDeleteElement={handleDeleteElement}
          handleAddElement={handleAddElement}
          handleEditElement={handleEditElement}
          handleDropElement={handleDropElement}
        />
      ))}
    </>
  );
};
export default TreeViewComponent;
