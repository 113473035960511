import { Typography } from "@mui/material";
import Layout from "layout/Layout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Chat from "modules/KnowledgeBase/Chat";

const KnowledgeBasePage = () => {
  const { t } = useTranslation("common");
  const [isFullscreen, setFullscreen] = useState<boolean>(false);

  return (
    <Layout hiddenMenu>
      {!isFullscreen && <Typography variant="h1">{t("knowledge_base_label")}</Typography>}
      <Chat isFullscreen={isFullscreen} setFullscreen={setFullscreen} />
    </Layout>
  );
};
export default KnowledgeBasePage;
