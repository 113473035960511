import React, { FC, useRef } from "react";
import { ItemTypes, TreeViewConfig, TreeViewData, TreeViewDataType } from "./types";
import Checkbox from "@mui/material/Checkbox";
import { Box, Icon, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import _ from "lodash";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useDrag, useDrop } from "react-dnd";
import IconButton from "@mui/material/IconButton";

type TreeViewItemProps = TreeViewData & {
  tree: TreeViewData[];
  config: TreeViewConfig;
  level?: number;
  open?: number[];
  selectedId?: string;
  onRowClick?: (idString: string) => void;
  onToggleExpanded?: (id: number) => void;
  checkboxed?: string[];
  onToggleCheckbox?: (ids: string[]) => void;
  handleEditElement?: (id: number, title: string, childs?: TreeViewData[]) => void;
  handleAddElement?: (id: number) => void;
  handleDeleteElement?: (id: number, type: TreeViewDataType, parentId?: number) => void;
  handleDropElement?: (
    item: { id: number; parentId?: number; type: TreeViewDataType },
    thrownAtItem: { id: number; parentId?: number; type: TreeViewDataType },
  ) => void;
};

const makeIdString = (item: { id: number; type: TreeViewDataType; parentId?: number }) => {
  return `${item.type}-${item.id}${item.parentId !== null ? "-" + item.parentId : ""}`;
};

const TreeViewItem: FC<TreeViewItemProps> = ({
  tree,
  config,
  id,
  title,
  type,
  childs = [],
  clickable = true,
  checkable = true,
  level = 0,
  open = [],
  selectedId,
  onRowClick = () => null,
  onToggleExpanded = () => null,
  checkboxed = [],
  onToggleCheckbox = () => null,
  handleDeleteElement,
  handleAddElement,
  handleEditElement,
  handleDropElement,
  parentId,
}) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.ITEM,
      item: { id, parentId, type },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [id, parentId, type],
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.ITEM,
      drop: (item: { id: number; parentId?: number; type: TreeViewDataType }) =>
        handleDropElement && handleDropElement(item, { id: id, parentId: parentId, type: type }),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [id, parentId, type, handleDropElement],
  );

  const idString = makeIdString({ id: id, type: type, parentId: parentId });
  const isSelected = selectedId === idString;

  const itemStyle: any = {
    paddingLeft: `${level * 50}px`,
    display: "flex",
    alignItems: "center",
    backgroundColor: (isSelected && "rgba(78, 199, 232, .15)") || (isOver && "yellow"),
    cursor: clickable ? "pointer" : "default",
    opacity: isDragging ? 0.5 : 1,
    minHeight: "2.6rem",
  };

  const isOpen = open.indexOf(id) > -1;
  const hasChild = childs.length > 0;
  const isChecked = checkboxed.indexOf(idString) > -1;

  const treeWithParents = (
    parent: TreeViewData | null,
    tree: TreeViewData[],
    level = 0,
  ): (TreeViewData & { parent: TreeViewData | null; level: number })[] => {
    const all = tree.reduce(
      (acc, item: TreeViewData) => {
        acc.push({ ...item, parent, level });
        if (item.childs) {
          acc.push(...treeWithParents(item, item.childs, level + 1));
        }
        return acc;
      },
      [] as (TreeViewData & { parent: TreeViewData | null; level: number })[],
    );
    return _.reverse(_.sortBy(all, (o) => o.level));
  };

  const parentalTree = treeWithParents(null, tree);

  const handleCheckboxClick = (val) => {
    let items = [idString];

    if (hasChild) {
      items = [...items, ...idsChilds(childs)];
    }
    if (val.target.checked) {
      let allItems = _.uniq([...checkboxed, ...items]);
      allItems = _.uniq([...allItems, ...parentsToCheck(id, allItems)]);
      onToggleCheckbox(allItems);
    } else {
      const allItems = _.uniq(checkboxed.filter((item) => items.indexOf(item) === -1));
      const toUncheck = parentsToUnCheck(id, allItems);
      onToggleCheckbox(toUncheck);
    }
  };

  const parentsToCheck = (id: number, allItems: string[]): string[] => {
    let result = [...allItems];
    const elementsWithChildren = parentalTree.filter((it) => it.childs && it.childs.length > 0);

    elementsWithChildren.forEach((itemWithChild) => {
      const childsStringIds = itemWithChild?.childs?.map((item) => makeIdString(item));

      if (_.difference(childsStringIds, result).length === 0) {
        const stringIds = makeIdString(itemWithChild);
        result = [stringIds, ...result];
      }
    });
    return _.uniq(result);
  };

  const parentsToUnCheck = (id: number, allItems: string[]): string[] => {
    let result = [...allItems];
    const elementsWithChildren = parentalTree.filter((it) => it.childs && it.childs.length > 0);
    elementsWithChildren.forEach((itemWithChild) => {
      const childsStringIds = itemWithChild?.childs?.map((item) => makeIdString(item));
      if (_.difference(childsStringIds, result).length !== 0) {
        result = [...result.filter((o) => o !== makeIdString(itemWithChild))];
      }
    });
    return _.uniq(result);
  };

  const idsChilds = (childs: any[]) => {
    let result: string[] = [];

    for (const child of childs) {
      const checked = makeIdString(child);
      result.push(checked);

      result = [...result, ...idsChilds(child.childs)];
    }

    return result;
  };

  if (handleDropElement) {
    type === TreeViewDataType.GROUP ? drag(drop(ref)) : drag(ref);
  }

  return (
    <React.Fragment>
      <Box style={itemStyle} ref={ref}>
        {hasChild &&
          (isOpen ? (
            <RemoveIcon onClick={() => onToggleExpanded(id)} />
          ) : (
            <AddIcon onClick={() => onToggleExpanded(id)} />
          ))}
        {childs.length === 0 && <Icon />}
        {checkable && <Checkbox checked={isChecked} size="small" disabled={!checkable} onClick={handleCheckboxClick} />}
        {type === TreeViewDataType.ITEM && config.itemIcon}
        {type === TreeViewDataType.GROUP && !isOpen && config.groupClosed}
        {type === TreeViewDataType.GROUP && isOpen && config.groupOpened}
        <Typography
          pl={2}
          sx={{ width: "100%", fontWeight: type === TreeViewDataType.GROUP ? "bold" : "normal" }}
          onClick={(e) => {
            e.preventDefault();
            clickable && onRowClick(idString);
          }}
        >
          {title}
        </Typography>
        {isSelected && (
          <Box display="flex" justifyContent={"flex-end"} alignItems={"center"}>
            {type === TreeViewDataType.GROUP && handleAddElement && (
              <IconButton onClick={() => handleAddElement(id)}>
                <AddBoxIcon fontSize="small" />
              </IconButton>
            )}
            {type === TreeViewDataType.GROUP && handleEditElement && (
              <IconButton onClick={() => handleEditElement(id, title, childs)}>
                <ModeEditIcon fontSize="small" />
              </IconButton>
            )}
            {handleDeleteElement && (
              <IconButton onClick={() => handleDeleteElement(id, type, parentId)}>
                <DeleteForeverIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
      {isOpen &&
        childs.map((child) => (
          <TreeViewItem
            tree={tree}
            config={config}
            key={`tree-item-${child.id}-${child.type}`}
            {...child}
            level={level + 1}
            selectedId={selectedId}
            onRowClick={onRowClick}
            onToggleExpanded={onToggleExpanded}
            open={open}
            checkboxed={checkboxed}
            onToggleCheckbox={onToggleCheckbox}
            handleEditElement={handleEditElement}
            handleDeleteElement={handleDeleteElement}
            handleAddElement={handleAddElement}
            handleDropElement={handleDropElement}
          />
        ))}
    </React.Fragment>
  );
};
export default TreeViewItem;
