import { configureStore } from "@reduxjs/toolkit";

import crud from "./slices/crud";
import groups from "./slices/groups";
import theme from "./slices/theme";
import user from "./slices/user";
import menuSlices from "./slices/menu";
import languageSlice from "./slices/language";
import organizationSlice from "./slices/organizations";

const store = configureStore({
  reducer: {
    theme: theme,
    user: user,
    crud: crud,
    groups: groups,
    menu: menuSlices,
    language: languageSlice,
    organization: organizationSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
