import React, { FC } from "react";

import TableCell from "@mui/material/TableCell";
import { ListItem, ListItemText } from "@mui/material";
type CellNumberInvoiceProps = {
  data: any;
  handleClick?: (e: any, row: any) => void;
  row?: number;
};
const CellNumberInvoice: FC<CellNumberInvoiceProps> = ({ data, handleClick, row }) => {
  return (
    <TableCell onClick={(e) => (handleClick && row ? handleClick(e, row) : null)}>
      {!data?.examinationNumbers ? (
        data?.invoiceNumber
      ) : (
        <ListItem>
          <ListItemText primary={data?.invoiceNumber} secondary={"Numer badania: " + data?.examinationNumbers} />
        </ListItem>
      )}
    </TableCell>
  );
};

export default CellNumberInvoice;
