async function fetcher(
  url,
  payload,
  method = "GET",
  headers = {
    "Content-Type": "application/json",
  },
  credentials = "include",
) {
  const options = {
    method,
    ...(method !== "GET" && { body: JSON.stringify(payload) }),
    ...(headers && { headers }),
    ...(credentials && { credentials }),
  };
  const response = await fetch(url, options);
  const data = await response.json();
  if (response.ok) {
    return data;
  }
}
export default fetcher;
