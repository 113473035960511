import React from "react";

import TableCell from "@mui/material/TableCell";

import { PRODUCT_TYPES } from "enums/productTypes";

const CellProductType = ({ data, handleClick, row }) => {
  return (
    <TableCell onClick={(e) => handleClick(e, row)}>
      {data && PRODUCT_TYPES.find((type) => type.id === data)?.displayName}
    </TableCell>
  );
};

export default CellProductType;
