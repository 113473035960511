import { Paper, Typography } from "@mui/material";
import React from "react";
import Image from "mui-image";

const News = () => {
  return (
    <Paper sx={{ padding: 4 }}>
      <Image width="100%" src="/news/image.avif" style={{ borderRadius: 16 }} />
      <Typography variant="h6" pt={4}>
        TECHNOLOGY
      </Typography>
      <Typography variant="h4" pb={1}>
        Architectural Engineering Wonders of the modern era for your Inspiration
      </Typography>
      <Typography pb={2}>
        Reinvention often comes in spurts, after a long period of silence. Just as modern architecture recently enjoyed
        a comeback, brand architecture, a field with well-established principles for decades, is back in the limelight.
      </Typography>
      <Typography variant="h6" sx={{ color: "#c0c0c0" }}>
        Jhony Bravo • October 21,2033
      </Typography>
    </Paper>
  );
};
export default News;
