import React, { FC, memo, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { debounce } from "throttle-debounce";

import { InputAdornment, ListItemButton, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { alpha } from "@mui/material/styles";

import * as color from "assets/styles/jss/common/colors";
import { Item } from "types/types";

type CategoryEditListPanelProps = {
  checkedId: number;
  setCheckedId: (id: number) => void;
  arrayItems: Item[];
  setFilter: (filter: string) => void;
  nameToDisplay?: string;
  namePanel?: string;
};

const CategoryEditListPanel: FC<CategoryEditListPanelProps> = ({
  checkedId,
  setCheckedId,
  arrayItems,
  setFilter,
  nameToDisplay = "title",
  namePanel,
}) => {
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState<Item[]>([]);
  const [count, setCount] = useState({
    prev: 0,
    next: 10,
  });

  const debounceSetFilter = debounce(250, (filter) => {
    setFilter(filter);
  });

  useEffect(() => {
    if (arrayItems?.length > 0) {
      const element = document.getElementById("listItem-" + namePanel + "-0");
      if (element) {
        element.scrollIntoView();
      }
    }
    setCount({ prev: 0, next: 10 });
    setCurrent(arrayItems.slice(0, 10));
    setHasMore(arrayItems?.length > 10);
  }, [arrayItems]);

  const getMoreData = () => {
    if (current.length === arrayItems.length) {
      setHasMore(false);
      return;
    }

    setCurrent(current.concat(arrayItems.slice(count.prev + 10, count.next + 10)));
    setCount((prevState) => ({ prev: prevState.prev + 10, next: prevState.next + 10 }));
  };

  return (
    <>
      <TextField
        placeholder={"Szukaj"}
        fullWidth
        type="search"
        variant="outlined"
        size="small"
        onChange={(e) => debounceSetFilter(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <List
        id={"scrollableDiv-" + namePanel}
        dense
        sx={{
          backgroundColor: color.white,
          borderRadius: "8px",
          border: "1px solid " + alpha(color.primary, 0.15),
          width: "100%",
          height: "300px",
          margin: "20px 0 30px 0",
          maxWidth: 360,
          overflow: "auto",
          position: "relative",
          "& ul": { padding: 0 },
        }}
      >
        <InfiniteScroll
          dataLength={current.length}
          next={getMoreData}
          hasMore={hasMore}
          scrollableTarget={"scrollableDiv-" + namePanel}
          loader={<Typography paragraph={true}>Wczytywanie...</Typography>}
        >
          <>
            {current &&
              current.map((item, index) => (
                <ListItem id={"listItem-" + namePanel + "-" + index} key={item.id} sx={{ p: 0 }}>
                  <ListItemButton
                    selected={item.id === checkedId}
                    onClick={() => {
                      setCheckedId(item.id);
                    }}
                  >
                    {item[nameToDisplay]}
                  </ListItemButton>
                </ListItem>
              ))}
          </>
        </InfiniteScroll>
      </List>
    </>
  );
};
export default memo(CategoryEditListPanel);
