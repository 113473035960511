import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import ChatHistory from "modules/KnowledgeBase/components/main/ChatHistory";
import { mainPanelBackground, mainPanelQuestion } from "assets/colors";
import ChatNewMessage from "modules/KnowledgeBase/components/main/ChatNewMessage";
import React, { FC } from "react";
import { ChatType } from "modules/KnowledgeBase/types/types";

type ChatMainPanelProps = {
  sendMessage: (message: string, ref: any) => void;
  openSettings: () => void;
  setFullscreen: (value: any) => void;
  isFullscreen: boolean;
  chat: ChatType;
};

const ChatMainPanel: FC<ChatMainPanelProps> = ({ sendMessage, openSettings, chat, isFullscreen, setFullscreen }) => {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      width="100vw"
      height="80vh"
      sx={{ background: mainPanelBackground, marginY: "0" }}
    >
      <Box height="66vh" width="90%" marginY={"2vh"} sx={{ background: mainPanelQuestion }}>
        <ChatHistory chat={chat} />
      </Box>
      <Box height="6vh" width="90%" marginBottom={"4vh"}>
        <ChatNewMessage
          sendMessage={sendMessage}
          openSettings={openSettings}
          setFullscreen={setFullscreen}
          isFullscreen={isFullscreen}
        />
      </Box>
    </Stack>
  );
};

export default ChatMainPanel;
